import { Component, Input, OnInit, Output, EventEmitter, signal, OnChanges, SimpleChanges, ChangeDetectorRef, WritableSignal } from '@angular/core'
import { AddressModel } from "./shared/address.model"
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { Constants, CreateMode } from "../../constants"
import { GeocodeService } from "../services/geocode.service"
import { faKeyboard, faPencilAlt, faSync, faTrash, faTrashAlt, faArrowDown, faArrowUp, faClone, faLock, faLockOpen, faMinus, faPlus, faLocationDot, faStreetView, faBoxesStacked, faDolly, faTruck, faTruckRampBox } from "@fortawesome/free-solid-svg-icons"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { environment } from 'src/environments/environment'
import { BackVehicleModel } from 'src/app/back-vehicles/shared/back-vehicle.model'
import { TransportEquipmentModel } from 'src/app/transport-equipments/shared/transport-equipment.model'
import { SharedService } from '../services/shared.service'
import { TranslateService } from '@ngx-translate/core'
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'
import { CustomerModel } from 'src/app/customers/shared/customer.model'
import { GoogleStreetViewModalComponent } from 'src/app/google-street-view-modal/google-street-view-modal.component'
import { TomTomLatLng } from 'src/app/tomtom-map-picker/shared/tomtom.model'
import { Product } from 'src/app/freight-types/shared/freight-types.model'
import { CustomerService } from 'src/app/customers/shared/customer.service'
import { CustomerSelectComponent } from 'src/app/tours/tour-create/customer-select/customer-select.component'
import { EventProxyService, ProductModalClose, ProductModalOpen, ProductModalEdit } from '../services/event-proxy.service'
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs'
import { ProductTypeService } from 'src/app/molecules/product-type-picker/helpers/product-type.service'

//import tt from '@tomtom-international/web-sdk-maps';

//https://developer.tomtom.com/blog/build-different/building-responsive-location-search-component-angular/

@Component({
  selector: 'app-address-widget',
  templateUrl: './address-widget.component.html',
  styleUrls: ['./address-widget.component.scss']
})
export class AddressWidgetComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined


  get createModes() { return CreateMode; }

  // @Input() formGroup!: FormArray
  @Input() addressIsRequired: boolean = true;
  @Input() address: AddressModel | undefined;
  @Input() addressIndex: number = 0
  @Input() addresses: FormArray<FormGroup>;
  @Input() arriveBackVehicle: string[] = [];
  @Input() arriveEquipment: string[] = []
  @Input() leaveBackVehicle: string[] = []
  @Input() leaveEquipment: string[] = []
  @Input() backVehicles: BackVehicleModel[] | undefined;
  @Input() createMode: any;
  @Input() customerAddresses: AddressModel[] | undefined
  @Input() freightTypeId = 0;
  @Input() inputFormGroup!: AbstractControl<any, any>
  @Input() reset = false;
  @Input() orderId: number = 0
  @Input() products: WritableSignal<Product[]> = signal([])
  @Input() showDescription = true;
  @Input() showCoordinates = true;
  @Input() showEditButton = true;
  @Input() showLocationNameAndId = true;
  @Input() showFreightSection = true;
  @Input() showFreightType = true;
  @Input() showUpdateAndCancelButtons = true
  @Input() transportEquipment: TransportEquipmentModel[] | undefined
  @Input() selectedCustomer: CustomerModel | null | undefined
  @Input() selectedBackVehicles: BackVehicleModel[] | undefined
  @Input() selectedTransportEquipment: TransportEquipmentModel | null | undefined
  @Input() showCreateButton: boolean = false
  @Input() readOnly: boolean = true
  @Input() showBillingAddress: boolean = false

  @Output() cancelCreate = new EventEmitter();
  @Output() createAddress = new EventEmitter()
  @Output() delete = new EventEmitter()
  @Output() duplicateEmit = new EventEmitter<any>()
  @Output() editAddress = new EventEmitter()
  @Output() removeAddressAtIndexEmit = new EventEmitter<number>()
  @Output() swapEmit = new EventEmitter<any>()
  @Output() showStreetview = new EventEmitter();
  @Output() arriveBackVehiclesEmitter = new EventEmitter<any>()
  @Output() updateReadOnlyEmitter = new EventEmitter<boolean>()


  addressSearchUpdate = new Subject<string>()
  selectArriveBackVehicle: string[] = []
  selectLeaveBackVehicle: string[] = []
  selectArriveEquipment: string[] = []
  selectLeaveEquipment: string[] = []
  customerAddress: AddressModel | undefined
  enterCustomAddress: boolean[] = [false]
  showMapPicker: boolean = false
  addressSearch: string[] = []
  addressOpenState: boolean[] = []
  customerSelect = false;
  customers: CustomerModel[] = []
  isGeocoding: boolean = false
  isReverseGeocoding: boolean = false
  showContactDropdown: boolean = false
  showContactForm: boolean = false
  showBothArriveAndLeave: boolean[] = [false]
  leaveAndArriveSections: any = []


  faSyncIcon = faSync;
  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faKeyboard = faKeyboard
  faCloneIcon = faClone
  faUpIcon = faArrowUp
  faDownIcon = faArrowDown
  faAddIcon = faPlus
  faTrashIcon = faTrash
  faMinusIcon = faMinus
  faLocationDot = faLocationDot
  faStreetView = faStreetView
  faBoxesStacked = faBoxesStacked
  faDolly = faDolly
  faTruck = faTruck
  faTruckRampBox = faTruckRampBox

  showArriveBackvehicle: boolean[] = [false]
  showLeaveBackvehicle: boolean[] = [false]
  showArriveEquipment: boolean[] = [false]
  showLeaveEquipment: boolean[] = [false]

  currentLat: number = 55.850849
  currentLon: number = 9.845612
  showSearchAddress: boolean = false

  currSearch: number = 0

  searchResults: any = []

  language: string | undefined;
  showArriveMultiSelect: boolean[] = [false]
  showLeaveMultiSelect: boolean[] = [false]
  dropdownList: any[] = []
  arriveBackVehicles: any[] = []
  leaveBackVehicles: any[] = []

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true,
    searchPlaceholderText: this.translate.instant('reports.multiselect.search'),
    noDataAvailablePlaceholderText: this.translate.instant('reports.multiselect.noDataAvailable'),
    itemsShowLimit: 3,
    limitSelection: 3
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private geocoder: GeocodeService,
    protected sharedService: SharedService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private customerService: CustomerService,
    private eventProxy: EventProxyService,
    private productTypeService: ProductTypeService
  ) {
    this.addresses = this.formBuilder.array([]);

    this.addresses.controls.forEach((address: FormGroup) => {
      if (!address.get('customer')) {
        address.addControl(
          'customer',
          this.formBuilder.group({
            id: '',
            cvrNumber: '',
            name: '',
            description: '',
            internalUse: '',
            addresses: '',
            contacts: this.formBuilder.array([
              this.formBuilder.group({
                name: '',
                title: '',
                phone: '',
                mobile: '',
                email: ''
              })
            ]),
            tags: '',
            notes: '',
            debtorNumber: '',
            debtor: '',
            creditor: '',
            termTypeId: '',
          })
        )


      }
    });


    this.sharedService.getLanguageJson().subscribe(response => this.language = response)

    // By debouncing, we avoid "too many requests" towards TomTom, as it's no longer updated with every keystroke
    this.addressSearchUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getValue(value)
      })

    this.EventProxySubscription = this.eventProxy.getEvent().subscribe((param: any) => {
      if (param instanceof ProductModalClose && param.data.type < 9999) {
        const addressGroup = this.addresses.at(param.data.type)

        // if addressGroup for some reason is undefined, we need to return early to avoid a push of 'null' into existingFormArray
        if (typeof addressGroup === 'undefined') return


        const existingFormArray = addressGroup?.get('addressFreights') as FormArray

        const itemExists = existingFormArray?.value.find((item: any) => {
          if (item) {
            return item.productTypeId === param.data.formGroup.value.productTypeId && item.type === param.data.formGroup.value.type
          }
          return false
        })

        // Create the new FormGroup
        const newFreightGroup = this.formBuilder.group({
          productTypeId: param.data.formGroup.value.productTypeId,
          productType: { name: param.data.formGroup.value.productType.name },
          freightUnit: param.data.formGroup.value.freightUnit,
          freightUnitId: param.data.formGroup.value.freightUnitId,
          type: param.data.formGroup.value.type,
          surcharges: [param.data.formGroup.value.surcharges.map((surcharge: any) => surcharge.id)],
          surchargesArray: [param.data.formGroup.value.surcharges],
          priceUnitId: param.data.formGroup.value.priceUnitId,
          amount: param.data.formGroup.value.amount
        })


        if (itemExists) {
          const index = existingFormArray?.value.findIndex((item: any) => {
            return item.productTypeId === param.data.formGroup.value.productTypeId
          })

          existingFormArray?.at(index)?.patchValue(newFreightGroup.value)
        } else {
          existingFormArray?.push(newFreightGroup)
        }
      }
    })
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }



  async ngOnInit(): Promise<void> {
    // to fill out the address customer select
    this.customerService.getAll().subscribe((result) => {
      this.customers = result
    })


    this.backVehicles?.forEach(backVehicle => {
      this.dropdownList.push({ item_id: backVehicle.id, item_text: backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber });
    });


    if (this.addresses.value.length > 0) {
      this.addressOpenState.push(false)
    }


    if (this.createMode == this.createModes.create_as_child) {
      this.readOnly = false
    }



    // when this.readOnly has been set correctly initially, handle the select fields properly
    this.handleSelectFields(this.readOnly)




    if (this.createMode) {
      // TODO: What is this for?
      // this.readOnly = false
      this.addresses.valueChanges.subscribe(() => {
        if ((this.createMode === this.createModes.create_as_user || this.createMode === this.createModes.create_as_parent)
          && this.addresses.dirty) {
          //this.createAddress.emit(this.addresses.value as AddressModel);
        }
      });
    }

    await this.getLanguage()

    this.addresses.controls.forEach((address: FormGroup, index: number) => {
      this.setPriority(index)
      this.patchFormValues(index)
      this.leaveAndArriveSections.push({ arrive: true, leave: true })
    })
  }


  ngOnChanges(changes: any) {
    /**
     * Since readOnly is changed from the parent component, we need to look at the change to the @input to see
     * what the current readOnly state is and act accordingly
     */
    if (changes.readOnly) {
      this.readOnly = changes.readOnly.currentValue
      this.handleSelectFields(this.readOnly)
    }
  }


  getAddressFreights(formGroup: FormGroup): FormArray {
    return formGroup.get('addressFreights') as FormArray;
  }

  toggleReadOnly() {
    this.readOnly = !this.readOnly
    this.handleSelectFields(!this.readOnly)
  }


  /**
   * Based on the readOnly state, enable or disable certain select fields
   */
  handleSelectFields(state: boolean) {
    this.addresses.controls.forEach((address, index: number) => {
      if (!state) {
        address.get('removedFreightType')?.enable()
        address.get('selectArriveEquipment')?.enable()
        address.get('selectLeaveEquipment')?.enable()
        address.get('removedFreightType')?.enable()
      }

      if (state) {
        address.get('removedFreightType')?.disable()
        address.get('selectArriveEquipment')?.disable()
        address.get('selectLeaveEquipment')?.disable()
        address.get('removedFreightType')?.disable()
      }
    })
  }


  getAddressFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  getContacts(addressIndex: number): FormArray {
    return this.addresses.at(addressIndex).get('customer.contacts') as FormArray;
  }

  updateCustomerAddresses(input: any, isEventTarget?: boolean) {
    if (isEventTarget) {
      this.customerService.getSingle(input.target.value).subscribe((result) => {
        this.selectedCustomer = result // set the customer to display the address dropdown
        this.customerAddresses = result.addresses
      })

      return
    }

    if (input) {
      this.customerService.getSingle(input).subscribe((result) => {
        this.selectedCustomer = result // set the customer to display the address dropdown
        this.customerAddresses = result.addresses
      })

      return
    }
  }

  toggleArriveBackvehicle(addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      arriveBackVehicle: ''
    })
    this.showArriveBackvehicle[addressIndex] = !this.showArriveBackvehicle[addressIndex]
  }

  toggleLeaveBackvehicle(addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      leaveBackVehicle: ''
    })
    this.showLeaveBackvehicle[addressIndex] = !this.showLeaveBackvehicle[addressIndex]
  }

  toggleArriveEquipment(addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      arriveEquipment: ''
    })
    this.showArriveEquipment[addressIndex] = !this.showArriveEquipment[addressIndex]
  }

  toggleLeaveEquipment(addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      leaveEquipment: ''
    })
    this.showLeaveEquipment[addressIndex] = !this.showLeaveEquipment[addressIndex]
  }



  emitStreetview(addressIndex: number) {
    const addressForm = this.addresses.at(addressIndex) as FormGroup
    if (addressForm.value.latitude != undefined && addressForm.value.longitude != undefined) {
      let outputModel: TomTomLatLng = { lat: addressForm.value.latitude, lng: addressForm.value.longitude };
      this.showStreetview.emit(outputModel);
    }
  }


  // should update the associated input field as well as the leaveEquipment one
  selectedArriveEquipment(index: number) {
    this.arriveEquipment[index] = this.selectArriveEquipment[index]
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveEquipment(index: number) {
    this.leaveEquipment[index] = this.selectLeaveEquipment[index]
  }



  // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
  manualArriveBackVehicleInput(index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup

    addressGroup.patchValue({
      arriveBackVehicle: this.arriveBackVehicle[index],
      selectArriveBackVehicle: this.selectArriveBackVehicle
    })


  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveBackVehicleInput(index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup

    this.leaveBackVehicle = this.leaveBackVehicle

    addressGroup.patchValue({
      leaveBackVehicle: this.leaveBackVehicle,
      selectLeaveBackVehicle: this.selectLeaveBackVehicle
    })
  }

  // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
  manualArriveEquipmentInput(index: number) {
    this.arriveEquipment = this.arriveEquipment

    // clear dropdowns
    this.selectArriveEquipment[index] = ""
    this.selectLeaveEquipment[index] = ""
  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveEquipmentInput() {
    this.leaveEquipment = this.leaveEquipment
  }

  /**
   * When the user SELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   */
  onItemSelectArriveBackVehicle(item: any, index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup

    if (this.arriveBackVehicles[index] == undefined) this.arriveBackVehicles[index] = []

    this.arriveBackVehicles[index].push(item)

    let localIdArray = this.arriveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.arriveBackVehicles[index].map((item: any) => item.item_text)

    this.arriveBackVehicle[index] = localTextArray.join(',')
    this.selectArriveBackVehicle[index] = localIdArray.join(',')

    addressGroup.patchValue({
      arriveBackVehicle: localIdArray.join(','),
      // leaveBackVehicle: localTextArray.join(','),
      selectArriveBackVehicle: localTextArray.join(','),
      // selectLeaveBackVehicle: localTextArray.join(',')
    })
  }

  logFormGroup() {
  }

  /**
   * When the user DESELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   */
  onItemDeSelectArriveBackVehicle(item: any, index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup
    if (this.arriveBackVehicles[index] != undefined) this.arriveBackVehicles[index].splice(this.arriveBackVehicles[index].findIndex((x: any) => x.item_id === item.item_id), 1)

    let localIdArray = this.arriveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.arriveBackVehicles[index].map((item: any) => item.item_text)

    this.arriveBackVehicle[index] = localTextArray.join(',')

    addressGroup.patchValue({
      arriveBackVehicle: localIdArray.join(','),
      // leaveBackVehicle: localTextArray.join(','),
      selectArriveBackVehicle: localTextArray.join(','),
      // selectLeaveBackVehicle: localTextArray.join(',')
    })
  }

  /**
   * When the user selects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
  */
  onItemSelectLeaveBackVehicle(item: any, index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup

    if (this.leaveBackVehicles[index] == undefined) this.leaveBackVehicles[index] = []

    this.leaveBackVehicles[index].push(item)

    let localIdArray = this.leaveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.leaveBackVehicles[index].map((item: any) => item.item_text)

    this.leaveBackVehicle[index] = localTextArray.join(',')
    this.selectLeaveBackVehicle[index] = localIdArray.join(',')

    addressGroup.patchValue({
      leaveBackVehicle: localIdArray.join(','),
      // leaveBackVehicle: localTextArray.join(','),
      selectLeaveBackVehicle: localTextArray.join(','),
      // selectLeaveBackVehicle: localTextArray.join(',')
    })
  }



  /**
   * When the user deselects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
   */
  onItemDeSelectLeaveBackVehicle(item: any, index: number) {
    const addressGroup = this.addresses.at(index) as FormGroup
    if (this.leaveBackVehicles != null) this.leaveBackVehicles.splice(this.leaveBackVehicles.findIndex((x: any) => x.item_id === item.item_id), 1)

    let localIdArray = this.leaveBackVehicles.map((item: any) => item.item_id)
    let localTextArray = this.leaveBackVehicles.map((item: any) => item.item_text)

    this.leaveBackVehicle[index] = localTextArray.join(',')
    addressGroup.patchValue({
      leaveBackVehicle: localIdArray.join(','),
      selectLeaveBackVehicle: localTextArray.join(',')
    })
  }


  /**
   * When the user selects a value from the multiselect dropdown for customer address
   * @param input the html input element - input.target.value is the value of the selected option
   * @param addressIndex the index get from the top for loop
   * @returns
   */
  updateAddress(input: any, addressIndex: number) {
    this.clearAddress(addressIndex)

    let selectedAddress

    if (input.target.value !== 'enter') {
      selectedAddress = this.customerAddresses?.find((address: AddressModel) => address.id === parseInt(input.target.value))

      this.enterCustomAddress[addressIndex] = false
      this.showSearchAddress = false

      if (selectedAddress == undefined) return

      this.addresses.at(addressIndex).patchValue({
        locationName: `${selectedAddress.street}, ${selectedAddress.zipcode} ${selectedAddress.city}, ${selectedAddress.country}`,
        country: selectedAddress.country,
        state: selectedAddress.state,
        zipcode: selectedAddress.zipcode,
        city: selectedAddress.city,
        street: selectedAddress.street,
        latitude: selectedAddress.latitude,
        longitude: selectedAddress.longitude,
        description: selectedAddress.description
      })
    } else {
      this.enterCustomAddress[addressIndex] = true
      this.showSearchAddress = true
    }
  }



  /**
   * When the user selects a value from the multiselect dropdown for customer address
   * @param input the html input element - input.target.value is the value of the selected option
   * @param addressIndex the index get from the top for loop
   * @returns
   */
  updateContact(input: any, addressIndex: number) {
    // this.clearAddress(addressIndex);

    let selectedContact;

    if (input.target.value !== 'enter') {
      let selectedAddress = this.customerAddresses?.find((address: AddressModel) => {
        return address.id === parseInt(this.addresses.at(addressIndex).value.id);
      });

      selectedContact = selectedAddress?.contact

      this.enterCustomAddress[addressIndex] = false;
      this.showSearchAddress = false;

      if (selectedContact == undefined) return;

      // Check if 'contact' already exists
      let contactControl = this.addresses.at(addressIndex).get('contact');

      // If 'contact' doesn't exist, create it
      if (!contactControl) {
        this.addresses.at(addressIndex).addControl(
          'contact',
          this.formBuilder.group({
            name: [selectedContact.name],
            title: [selectedContact.title],
            phone: [selectedContact.phone],
            mobile: [selectedContact.mobile],
            email: [selectedContact.email],
          })
        );
      } else {
        // Update the existing 'contact' control
        (contactControl as FormGroup).patchValue({
          name: selectedContact.name,
          title: selectedContact.title,
          phone: selectedContact.phone,
          mobile: selectedContact.mobile,
          email: selectedContact.email,
        });
      }
    } else {
      this.showContactForm = true;
    }
  }


  /**
   * Simple method for clearning method with the address dropdown select
   * @param addressIndex the index on the addresses array to modify
   */
  clearAddress(addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      country: '',
      state: '',
      zipcode: '',
      city: '',
      street: '',
      latitude: 0,
      longitude: 0,
      description: ''
    })
  }


  /**
   * Updates the address by picking a spot on the map
   * @param input
   * @param addressIndex
   */
  updateCoordinates(input: any, addressIndex: number) {
    this.geocoder.reverseGeocodeAddress(input.lat, input.lng).subscribe(response => {
      this.addresses.at(addressIndex).patchValue({
        locationName: `${response.street}, ${response.zipcode} ${response.city}, ${response.country}`,
        country: response.country,
        state: response.state,
        zipcode: response.zipcode,
        city: response.city,
        street: response.street,
        latitude: response.latitude,
        longitude: response.longitude,
        description: response.description
      })
    })

    this.enterCustomAddress[addressIndex] = true
    this.showMapPicker = false
  }



  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchArriveField(addressIndex: number): void {
    this.showArriveMultiSelect[addressIndex] = !this.showArriveMultiSelect[addressIndex]
  }

  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchLeaveField(addressIndex: number): void {
    this.showLeaveMultiSelect[addressIndex] = !this.showLeaveMultiSelect[addressIndex]
  }



  private patchFormValues(index: number): void {
    const addressGroup = this.addresses.at(index) as FormGroup
    let model = this.addresses.at(index) as any

    let arriveBVInputString = ''
    let leaveBVInputString = ''

    if (model != undefined) {
      // generate readable arriveBackVehicle strings for the input fields
      if (model.value.arriveBackVehicles != undefined && model.value.arriveBackVehicles.length > 0) {
        arriveBVInputString = model.value.arriveBackVehicles.map((backVehicle: any) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber).join(', ')
      } else {
        arriveBVInputString = model.value.arriveBackVehicle;
      }

      // generate readable leaveBackVehicle strings for the input fields
      if (model.value.leaveBackVehicles != undefined && model.value.leaveBackVehicles.length > 0) {
        leaveBVInputString = model.value.leaveBackVehicles.map((backVehicle: any) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber).join(', ')
      } else {
        leaveBVInputString = model.value.leaveBackVehicle;
      }
    }


    // TODO: Why is this even here?
    // if (this.arriveBackVehicle != undefined) arriveBVInputString = this.arriveBackVehicle[index]
    // if (this.leaveBackVehicle != undefined) leaveBVInputString = this.leaveBackVehicle[index]
    // if (this.arriveEquipment != undefined) arriveEquipmentString = this.arriveEquipment[index]
    // if (this.leaveEquipment != undefined) leaveEquipmentString = this.leaveEquipment[index]

    /* if (this.arriveBackVehicle != undefined && model != undefined) model.arriveBackVehicle = this.arriveBackVehicle;
    if (this.arriveEquipment != undefined && model != undefined) model.arriveEquipment = this.arriveEquipment; */

    /* let currArrBackV = model?.arriveBackVehicle;
    let currArrEqu = model?.arriveEquipment; */

    /* if (currArrBackV == undefined && this.arriveBackVehicle != undefined) currArrBackV = this.arriveBackVehicle;
    if (currArrEqu == undefined && this.arriveEquipment != undefined) currArrEqu = this.arriveEquipment; */


    addressGroup.patchValue({
      id: model?.value.id,
      country: model?.value.country,
      state: model?.value.state,
      zipcode: model?.value.zipcode,
      city: model?.value.city,
      street: model?.value.street,
      latitude: model?.value.latitude,
      longitude: model?.value.longitude,
      description: model?.value.description,
      locationName: model?.value.locationName,
      locationId: model?.value.locationId,
      orderId: model?.value.orderId,
      arriveBackVehicle: arriveBVInputString,
      arriveEquipment: model?.value.arriveEquipment,
      leaveBackVehicle: leaveBVInputString,
      leaveEquipment: model?.value.leaveEquipment,
      freight: model?.value.freight,
      removedFreightAmount: model?.value.removedFreightAmount,
      removedFreightType: model?.value.removedFreightType,
      freightAmount: model?.value.freightAmount,
      type: model?.value.type,
      freightTypeId: this.freightTypeId > 0 ? this.freightTypeId : model?.value.freightTypeId,
      contact: model?.value.contact,
      addressFreights: model?.value.addressFreights,

      arriveWindow: {
        from: model?.value.arriveWindow?.from ? new Date(new Date(model.value.arriveWindow.from).getTime() - new Date().getTimezoneOffset() * 60000).toISOString() : null,
        to: model?.value.arriveWindow?.to ? new Date(new Date(model.value.arriveWindow.to).getTime() - new Date().getTimezoneOffset() * 60000).toISOString() : null
      },
      isBillingAddress: model?.isBillingAddress
    })

    this.selectedFreightType = model?.freightTypeId?.toString() ?? '1';

    if (model?.notes == undefined && model != undefined) model.notes = [];
    if (model?.attachments == undefined && model != undefined) model.attachments = [];
  }



  create(): void {
    this.addresses.get('freightTypeId')?.enable();
    this.addresses.get('isBillingAddress')?.enable();
    this.createAddress.emit(this.addresses.value as unknown as AddressModel);
    this.addresses.reset();
  }



  update(): void {
    this.addresses.get('freightTypeId')?.enable();
    this.addresses.get('isBillingAddress')?.enable();
    if (this.addresses.valueChanges) {
      this.editAddress.emit(this.addresses.value as unknown as AddressModel);
    };
    
    this.readOnly = true;
    this.handleSelectFields(!this.readOnly)
  }


  cancelUpdate(): void {
    this.readOnly = true;
    this.handleSelectFields(!this.readOnly)
    // this.patchFormValues(0);
  }


  setPriority(i: number) {
    this.addresses.controls[i].patchValue({ priority: i + 1 })
    if (i == 0) {
      this.addresses.controls[i].patchValue({ type: 'start' });
    }
    else if (i == this.addresses.value.length - 1) {
      this.addresses.controls[i].patchValue({ type: 'stop' });
    }
    else {
      this.addresses.controls[i].patchValue({ type: 'waypoint' });
    }
  }



  removeAddressAtIndex(index: number): void {
    this.addresses.removeAt(index)
    this.leaveAndArriveSections.splice(index, 1)
    this.addressOpenState.splice(index, 1) // delete the tracked state
    for (let i = 0; i < this.addresses.value.length; i++) {
      this.setPriority(i);
    }
  }

  swap(index1: number, index2: number) {
    if (this.addresses && this.addresses.length > 0) {
      const extras = [...this.addresses.controls]


      if (index2 > 0 && index1 < extras.length - 1) {
        if (this.canSwap(extras[index1], extras[index2], index1, index2)) {
          [this.arriveBackVehicle[index1], this.arriveBackVehicle[index2]] = [this.arriveBackVehicle[index2], this.arriveBackVehicle[index1]];
          [extras[index1], extras[index2]] = [extras[index2], extras[index1]];
          this.addresses.controls = extras
        }
      }

      for (let i = 0; i < this.addresses.controls.length; i++) {
        this.setPriority(i);
      }
    }
  }



  canSwap(address1: any, address2: any, startIndex: number, endIndex: number) {
    let canSwap = true

    // loop addresses and find the last address that has the same freight types. When found, the current address shouldn't be able to be moved past it
    const startFreights = address1.get('addressFreights') as FormArray
    const endFreights = address2.get('addressFreights') as FormArray

    endFreights.controls.forEach((endFreight: any) => {
      startFreights.controls.forEach((startFreight: any) => {
        if (startFreight.value.productTypeId === endFreight.value.productTypeId && (startFreight.value.type === 2 || (startFreight.value.type === 1 && startFreight.value.type === 2))) {
          canSwap = false
        } else if (startFreight.value.productTypeId === endFreight.value.productTypeId && startFreight.value.type === 1) {
          canSwap = true
        } else {
          canSwap = true
        }
      })
    })

    return canSwap
  }


  geocode(): void {
    const addressGroup = this.addresses.at(0) as FormGroup
    this.isGeocoding = true;
    const values = this.addresses.value;
    // this.geocoder.geocodeAddress(values.street, values.zipcode, values.city, values.state, values.country).subscribe(response => {
    //   addressGroup.patchValue({ longitude: response.longitude, latitude: response.latitude });
    //   this.isGeocoding = false;
    // }, error => this.isGeocoding = false);
  }

  reverseGeocode(): void {
    const addressGroup = this.addresses.at(0) as FormGroup
    this.isReverseGeocoding = true;
    const values = this.addresses.value;
    // this.geocoder.reverseGeocodeAddress(values.latitude, values.longitude).subscribe(response => {
    //   addressGroup.patchValue({
    //     country: response.country,
    //     state: response.state,
    //     zipcode: response.zipcode,
    //     city: response.city,
    //     street: response.street,
    //   });
    //   this.isReverseGeocoding = false;
    // }, error => this.isReverseGeocoding = false);
  }

  getValue(value: string) {
    this.http.get(
      'https://api.tomtom.com/search/2/search/' +
      value + '.json?' +
      'lat=' + this.currentLat + '&' +
      'lon=' + this.currentLon + '&' +
      'minFuzzyLevel=1&' +
      'maxFuzzyLevel=2&' +
      'view=Unified&' +
      'relatedPois=off&' +
      'returnPostalCodeForPOI=true&' +
      'typeahead=true&' +
      'key=' + environment.tomtom.key)
      .subscribe((data: any) => (this.searchResults = data['results']));
  }

  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }


  /**
     * Sends event for tour.component to listen to and open the modal
     * This is a workaround to avoid a css nightmare by trying to place the fixed modal in the sidebar
     */
  openProductModal(addressIndex?: number, addressType?: number, type?: string) {
    this.eventProxy.setEvent(new ProductModalOpen({
      addressType: addressType,
      addressIndex: addressIndex || addressIndex == 0 ? addressIndex : 9999,
      addresses: this.addresses,
      formGroup: this.formBuilder.group({
        productTypeId: 0,
        productType: { name: '' },
        type: '',
        surcharges: this.formBuilder.array([]),
        priceUnitId: null,
        amount: 0,
        freightUnit: '',
        freightUnitId: null,
        freightTypeId: ''
      })
    }))
  }



  fillAddressForm(result: any, address: FormArray, index: number) {
    this.addressSearch[index] = '' // empty the first search field

    if (result.address !== undefined) {

      let city = "";

      if (result.address.municipality !== undefined) city = result.address.municipality;
      if (result.address.municipalitySubdivision !== undefined) city = result.address.municipalitySubdivision;

      if (result.address.streetName !== undefined) address.at(index).patchValue({ street: result.address.streetName });
      if (result.address.streetName !== undefined && result.address.streetNumber !== undefined) address.at(index).patchValue({ street: result.address.streetName + " " + result.address.streetNumber });
      if (result.address.municipality !== undefined) address.at(index).patchValue({ city: city });
      if (result.address.country !== undefined) address.at(index).patchValue({ country: result.address.country });
      if (result.address.postalCode !== undefined) address.at(index).patchValue({ zipcode: result.address.postalCode });
      if (result.address.countrySubdivision !== undefined) address.at(index).patchValue({ state: result.address.countrySubdivision });
    }

    if (result.position !== undefined) {
      address.at(index).patchValue({ longitude: result.position.lon, latitude: result.position.lat });
    }
    this.searchResults = null;
    this.enterCustomAddress[index] = true
    this.editAddress.emit(address.value as AddressModel);
  }

  selectedFreightType: any;

  //event handler for the select element's change event
  freightTypeIdSelectChangeHandler(event: any) {
    //update the ui
    this.selectedFreightType = event.target.value;
  }




  addArriveAddress(): void {
    if (this.selectedCustomer) {
      this.updateCustomerAddresses(this.selectedCustomer?.id)
    }

    const counter = this.addresses.value.length - 1
    const selectedBackvehiclesExists = (this.selectedBackVehicles && this.selectedBackVehicles.length > 0)

    let firstAddressBackVehicleAndEquipment = {
      arriveBackVehicle: '',
      selectArriveBackVehicle: '',
      arriveEquipment: '',
      selectArriveEquipment: ''
    }

    if (this.addresses.value[counter]) {
      const internalCounter = counter + 1
      const regEx = /^\d+(,\d+)*$/

      let localArriveTextJoin = ""
      let localArriveIdJoin = ""

      if (regEx.test(this.addresses.value[counter].arriveBackVehicle)) {
        localArriveTextJoin = this.arriveBackVehicles[counter].map((item: any) => item.item_text).join(', ')
        localArriveIdJoin = this.arriveBackVehicles[counter].map((item: any) => item.item_id).join(',')
      } else {
        localArriveTextJoin = this.addresses.value[counter].arriveBackVehicle
        localArriveIdJoin = this.addresses.value[counter].arriveBackVehicle
      }

      if (this.addresses.value[counter].arriveBackVehicle) this.arriveBackVehicle[internalCounter] = localArriveTextJoin
      if (this.addresses.value[counter].arriveEquipment) this.arriveEquipment[internalCounter] = this.addresses.value[counter].arriveEquipment
      if (this.addresses.value[counter].selectArriveEquipment) this.selectArriveEquipment[internalCounter] = this.addresses.value[counter].selectArriveEquipment

      firstAddressBackVehicleAndEquipment = {
        arriveBackVehicle: localArriveIdJoin,
        selectArriveBackVehicle: localArriveIdJoin,
        arriveEquipment: this.addresses.value[counter].arriveEquipment,
        selectArriveEquipment: this.addresses.value[counter].selectArriveEquipment
      }
    } else {
      const internalCounter = counter + 1
      let arriveBackVehicleString: string[] = []
      let arriveBackVehicleIdString: string[] = []

      if (selectedBackvehiclesExists && this.selectedBackVehicles) {
        this.selectedBackVehicles.forEach((backVehicle: any, index: number) => {
          arriveBackVehicleString.push(`${backVehicle.unitIdentification} / ${backVehicle.registrationNumber}`)
          arriveBackVehicleIdString.push(`${backVehicle.id}`)
        })
        this.arriveBackVehicle[internalCounter] = arriveBackVehicleString.join(',')
      } else {
        this.arriveBackVehicle[internalCounter] = ''
      }

      this.arriveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''
      this.selectArriveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''

      firstAddressBackVehicleAndEquipment = {
        arriveBackVehicle: arriveBackVehicleIdString.join(','),
        selectArriveBackVehicle: arriveBackVehicleIdString.join(','),
        arriveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : '',
        selectArriveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''
      }
    }

    const addressForm = this.formBuilder.group({
      addressType: ['arrive'],
      country: ['', Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', [Validators.maxLength(10), Validators.required]],
      city: ['', [Validators.maxLength(255), Validators.required]],
      street: ['', Validators.maxLength(255)],
      latitude: 0,
      longitude: 0,
      priority: [this.addresses.value.length + 1],
      description: '',
      additionalInformation: '',
      locationName: ['', Validators.maxLength(255)],
      locationId: ['', Validators.maxLength(255)],
      freight: ['', [Validators.maxLength(255)]],
      freightTotalAmount: 0,
      freightTypeId: this.addresses.value.length > 0 && this.addresses.at(counter).value.freightTypeId > 0 ? this.addresses.at(counter).value.freightTypeId : 0,
      freightUnit: '',
      surcharge: '',
      removedFreight: '',
      removedFreightAmount: 0,
      removedFreightType: '',
      removedFreightUnit: '',
      removedSurcharge: '',
      notes: [],
      attachments: [],
      orderId: [this.orderId ?? this.orderId, [Validators.maxLength(40)]],
      arriveBackVehicle: [firstAddressBackVehicleAndEquipment.arriveBackVehicle, Validators.maxLength(255)],
      selectArriveBackVehicle: [firstAddressBackVehicleAndEquipment.selectArriveBackVehicle, Validators.maxLength(255)],
      arriveEquipment: [firstAddressBackVehicleAndEquipment.arriveEquipment, Validators.maxLength(255)],
      selectArriveEquipment: [firstAddressBackVehicleAndEquipment.selectArriveEquipment, Validators.maxLength(255)],
      leaveBackVehicle: ['', Validators.maxLength(255)],
      selectLeaveBackVehicle: ['', Validators.maxLength(255)],
      leaveEquipment: ['', Validators.maxLength(255)],
      selectLeaveEquipment: ['', Validators.maxLength(255)],
      addressFreights: this.formBuilder.array([]),
      arriveWindow: this.formBuilder.group({
        from: '',
        to: ''
      }),
      customer: this.formBuilder.group({
        id: this.selectedCustomer?.id ? this.selectedCustomer?.id : null,
        name: this.selectedCustomer?.name,
        contacts: this.formBuilder.array([
          this.formBuilder.group({
            name: '',
            title: '',
            phone: '',
            mobile: '',
            email: ''
          })
        ]),
      })
    })

    // decide which section to show
    this.leaveAndArriveSections.push({ arrive: true, leave: false })

    for (let index = 0; index < this.addressOpenState.length; index++) {
      this.addressOpenState[index] = false
    }

    // push a new open address
    this.addressOpenState.push(true)

    // Push the new form group to the FormArray
    this.addresses.push(addressForm)
  }






  addLeaveAddress(): void {
    if (this.selectedCustomer) {
      this.updateCustomerAddresses(this.selectedCustomer?.id)
    }

    const counter = this.addresses.value.length - 1
    const selectedBackvehiclesExists = (this.selectedBackVehicles && this.selectedBackVehicles.length > 0)

    let firstAddressBackVehicleAndEquipment = {
      leaveBackVehicle: '',
      selectLeaveBackVehicle: '',
      leaveEquipment: '',
      selectLeaveEquipment: ''
    }

    if (this.addresses.value[counter]) {
      const internalCounter = counter + 1
      const regEx = /^\d+(,\d+)*$/

      let localLeaveTextJoin = ""
      let localLeaveIdJoin = ""

      if (regEx.test(this.addresses.value[counter].leaveBackVehicle)) {
        localLeaveTextJoin = this.leaveBackVehicles[counter].map((item: any) => item.item_text).join(', ')
        localLeaveIdJoin = this.leaveBackVehicles[counter].map((item: any) => item.item_id).join(',')
      } else {
        localLeaveTextJoin = this.addresses.value[counter].leaveBackVehicle
        localLeaveIdJoin = this.addresses.value[counter].leaveBackVehicle
      }

      if (this.addresses.value[counter].leaveBackVehicle) this.leaveBackVehicle[internalCounter] = localLeaveTextJoin
      if (this.addresses.value[counter].leaveEquipment) this.leaveEquipment[internalCounter] = this.addresses.value[counter].leaveEquipment
      if (this.addresses.value[counter].selectLeaveEquipment) this.selectLeaveEquipment[internalCounter] = this.addresses.value[counter].selectLeaveEquipment

      firstAddressBackVehicleAndEquipment = {
        leaveBackVehicle: localLeaveIdJoin,
        selectLeaveBackVehicle: localLeaveIdJoin,
        leaveEquipment: this.addresses.at(counter).value.leaveEquipment,
        selectLeaveEquipment: this.addresses.at(counter).value.selectLeaveEquipment
      }
    } else {
      const internalCounter = counter + 1
      let leaveBackVehicleString: string[] = []
      let leaveBackVehicleIdString: string[] = []

      if (selectedBackvehiclesExists && this.selectedBackVehicles) {
        this.selectedBackVehicles.forEach((backVehicle: any, index: number) => {
          leaveBackVehicleString.push(`${backVehicle.unitIdentification} / ${backVehicle.registrationNumber}`)
          leaveBackVehicleIdString.push(`${backVehicle.id}`)
        })
        this.leaveBackVehicle[internalCounter] = leaveBackVehicleString.join(',')
      } else {
        this.leaveBackVehicle[internalCounter] = ''
      }

      this.leaveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''
      this.selectLeaveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''

      firstAddressBackVehicleAndEquipment = {
        leaveBackVehicle: leaveBackVehicleIdString.join(','),
        selectLeaveBackVehicle: leaveBackVehicleIdString.join(','),
        leaveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : '',
        selectLeaveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.resourceType.resourceTypeName}` : ''
      }
    }

    const addressForm = this.formBuilder.group({
      addressType: ['leave'],
      country: ['', Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', [Validators.maxLength(10), Validators.required]],
      city: ['', [Validators.maxLength(255), Validators.required]],
      street: ['', Validators.maxLength(255)],
      latitude: 0,
      longitude: 0,
      priority: [this.addresses.value.length + 1, Validators.required],
      description: '',
      additionalInformation: '',
      locationName: ['', Validators.maxLength(255)],
      locationId: ['', Validators.maxLength(255)],
      freight: ['', [Validators.maxLength(255)]],
      freightTotalAmount: 0,
      freightTypeId: this.addresses.value.length > 0 && this.addresses.at(counter).value.freightTypeId > 0 ? this.addresses.at(counter).value.freightTypeId : 0,
      freightUnit: '',
      surcharge: '',
      removedFreight: '',
      removedFreightAmount: 0,
      removedFreightType: [null],
      removedFreightUnit: '',
      removedSurcharge: '',
      notes: [],
      attachments: [],
      orderId: [this.orderId ?? this.orderId, [Validators.maxLength(40)]],
      arriveBackVehicle: ['', Validators.maxLength(255)],
      selectArriveBackVehicle: ['', Validators.maxLength(255)],
      arriveEquipment: ['', Validators.maxLength(255)],
      selectArriveEquipment: ['', Validators.maxLength(255)],
      leaveBackVehicle: [firstAddressBackVehicleAndEquipment.leaveBackVehicle, Validators.maxLength(255)],
      selectLeaveBackVehicle: [firstAddressBackVehicleAndEquipment.selectLeaveBackVehicle, Validators.maxLength(255)],
      leaveEquipment: [firstAddressBackVehicleAndEquipment.leaveEquipment, Validators.maxLength(255)],
      selectLeaveEquipment: [firstAddressBackVehicleAndEquipment.selectLeaveEquipment, Validators.maxLength(255)],
      addressFreights: this.formBuilder.array([]),
      arriveWindow: this.formBuilder.group({
        from: '',
        to: ''
      }),
      customer: this.formBuilder.group({
        id: this.selectedCustomer?.id ? this.selectedCustomer?.id : null,
        name: this.selectedCustomer?.name,
        contacts: this.formBuilder.array([
          this.formBuilder.group({
            name: '',
            title: '',
            phone: '',
            mobile: '',
            email: ''
          })
        ]),
      })
    })


    // decide which section to show
    this.leaveAndArriveSections.push({ arrive: false, leave: true })

    for (let index = 0; index < this.addressOpenState.length; index++) {
      this.addressOpenState[index] = false
    }

    // push a new open address
    this.addressOpenState.push(true)

    // Push the new form group to the FormArray
    this.addresses.push(addressForm)
  }




  addCustomerAddress() {
    const addressForm = this.formBuilder.group({
      country: ['', Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', [Validators.maxLength(10), Validators.required]],
      city: ['', [Validators.maxLength(255), Validators.required]],
      street: ['', Validators.maxLength(255)],
      latitude: 0,
      longitude: 0,
      priority: ['', Validators.required],
      description: '',
      additionalInformation: '',
      locationName: ['', Validators.maxLength(255)],
      locationId: ['', Validators.maxLength(255)],
      freight: ['', [Validators.maxLength(255)]],
      freightTotalAmount: 0,
      freightTypeId: 0,
      freightUnit: '',
      surcharge: '',
      removedFreight: '',
      removedFreightAmount: 0,
      removedFreightType: [null],
      removedFreightUnit: '',
      removedSurcharge: '',
      notes: [],
      attachments: [],
      orderId: ['', [Validators.maxLength(40)]],
      arriveBackVehicle: ['', Validators.maxLength(255)],
      selectArriveBackVehicle: ['', Validators.maxLength(255)],
      arriveEquipment: ['', Validators.maxLength(255)],
      selectArriveEquipment: ['', Validators.maxLength(255)],
      leaveBackVehicle: ['', Validators.maxLength(255)],
      selectLeaveBackVehicle: ['', Validators.maxLength(255)],
      leaveEquipment: ['', Validators.maxLength(255)],
      selectLeaveEquipment: ['', Validators.maxLength(255)],
      addressFreights: this.formBuilder.array([]),
      arriveWindow: this.formBuilder.group({
        from: '',
        to: ''
      }),
      customer: this.formBuilder.group({
        id: this.selectedCustomer?.id ? this.selectedCustomer?.id : null,
        name: this.selectedCustomer?.name,
        contacts: this.formBuilder.array([
          this.formBuilder.group({
            name: '',
            title: '',
            phone: '',
            mobile: '',
            email: ''
          })
        ]),
      })
    })

    // Push the new form group to the FormArray
    this.addresses.push(addressForm)
  }


  getAddressType(addressForm: any) {
    if (addressForm.get('addressFreights') && addressForm.get('addressFreights').value[0] != null) {
      if (
        addressForm.get('addressFreights').value.find((freight: any) => freight.type === 1) &&
        addressForm.get('addressFreights').value.find((freight: any) => freight.type === 2)
      ) {
        return 'both'
      }

      if (addressForm.get('addressFreights').value.find((freight: any) => freight.type === 1)) {
        return 'arrive'
      }

      if (addressForm.get('addressFreights').value.find((freight: any) => freight.type === 2)) {
        return 'leave'
      }
    }

    return 'neither'
  }




  /**
   * Cancel adding a new address to the tour
   * @param addressIndex the index of the address to remove
   */
  cancelAddressAdd(addressIndex: number) {
    if (window.confirm('Er du sikker på du vil fortryde?')) {
      this.addresses.removeAt(addressIndex)
      this.leaveAndArriveSections.splice(addressIndex, 1)
      this.addressOpenState.splice(addressIndex, 1)
    }
  }




  checkAddressFreightAndType(addressForm: any, typeToLookFor: number) {
    if (addressForm.get('addressFreights').value[0] == null) return false

    const formGroup = addressForm.get('addressFreights') as FormArray
    const exists = addressForm.get('addressFreights').value.find((freight: any) => freight.type === typeToLookFor)

    return formGroup.value.length > 0 && exists
  }

  showBothAddressAndLeaveOptions(addressForm: any, addressIndex: number) {
    this.showBothArriveAndLeave[addressIndex] = true
    this.leaveAndArriveSections[addressIndex] = { arrive: true, leave: true }
  }



  updateArriveWindow(timeString: any, arriveType: string, addressIndex: number) {
    const _timeString = timeString.target.value

    const addressGroup = this.addresses.at(addressIndex) as FormGroup
    const currentArriveWindow = addressGroup.get('arriveWindow')?.value || {};
 
    if (arriveType === 'from') {
      const arriveWindowFrom = this.convertToTodayDateTime(_timeString)

      addressGroup.patchValue({
        arriveWindow: {
          ...currentArriveWindow, 
          from: arriveWindowFrom 
        }
      });

       //addressGroup.get('arriveWindow')?.patchValue({ from: arriveWindowFrom })
    }

    if (arriveType === 'to') {
      const arriveWindowTo = this.convertToTodayDateTime(_timeString)

      addressGroup.patchValue({
        arriveWindow: {
          ...currentArriveWindow,
          to: arriveWindowTo 
        }
      });

      //addressGroup.get('arriveWindow')?.patchValue({ to: arriveWindowTo })
    }
  }


  convertToTodayDateTime(timeString: string) {
    if (timeString) {
      const now = new Date(); // Get the current date
      const [hours, minutes] = timeString.split(':'); // Extract hours and minutes

      // Set hours and minutes to today's date
      now.setHours(parseInt(hours), parseInt(minutes), 0, 0);
      return now
    }

    return
  }

  convertToTimestring(datestring: string) {

    if (datestring) {
  
      const now = new Date(datestring); // Get the current date

      const hours = now.getHours().toString().padStart(2, '0');
       const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;

    }

    return 
  }


  updateProductInfo(input: any, addressIndex: number, addressType: string) {
    const addressGroup = this.addresses.at(addressIndex) as FormGroup

    if (addressType === 'arrive') {
      addressGroup.patchValue({
        freight: input.value.freight,
        freightTypeId: input.value.freightTypeId,
        freightUnit: input.value.freightUnit,
        freightTotalAmount: input.value.freightTotalAmount,
        surcharge: input.value.surcharge
      })
    }

    if (addressType === 'leave') {
      addressGroup.patchValue({
        removedFreight: input.value.freight,
        removedFreightTypeId: input.value.freightTypeId,
        removedFreightUnit: input.value.freightUnit,
        removedFreightAmount: input.value.freightTotalAmount,
        removedSurcharge: input.value.surcharge
      })
    }
  }



  duplicate(address: any): void {
    const addressForm = this.formBuilder.group({
      country: [address.value.country, Validators.maxLength(255)],
      state: [address.value.state, Validators.maxLength(255)],
      zipcode: [address.value.zipcode, [Validators.maxLength(10), Validators.required]],
      city: [address.value.city, [Validators.maxLength(255), Validators.required]],
      street: [address.value.street, Validators.maxLength(255)],
      latitude: address.value.latitude,
      longitude: address.value.longitude,
      priority: [this.addresses.value.length + 1, Validators.required],
      description: address.value.description,
      additionalInformation: '',
      locationName: address.value.locationName,
      locationId: address.value.locationId,
      freight: address.value.freight,
      freightAmount: address.value.freightTotalAmount,
      freightTypeId: address.value.freightTypeId,

      removedFreightAmount: address.value.removedFreightAmount,
      removedFreightType: address.value.removedFreightType,
      orderId: ['', [Validators.maxLength(40)]],
      arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
      selectArriveBackVehicle: [this.selectArriveBackVehicle ? this.selectArriveBackVehicle : '', Validators.maxLength(255)],
      arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
      selectArriveEquipment: [this.selectArriveEquipment ? this.selectArriveEquipment : '', Validators.maxLength(255)],
      leaveBackVehicle: [this.leaveBackVehicle ? this.leaveBackVehicle : '', Validators.maxLength(255), Validators.maxLength(255)],
      selectLeaveBackVehicle: [this.selectLeaveBackVehicle ? this.selectLeaveBackVehicle : '', Validators.maxLength(255)],
      leaveEquipment: [this.leaveEquipment ? this.leaveEquipment : '', Validators.maxLength(255), Validators.maxLength(255)],
      selectLeaveEquipment: [this.selectLeaveEquipment ? this.selectLeaveEquipment : '', Validators.maxLength(255)],
      contact: address.value.contact
    })

    for (let index = 0; index < this.addressOpenState.length; index++) {
      this.addressOpenState[index] = false
    }

    // push a new open address
    this.addressOpenState.push(true)

    // Push the new form group to the FormArray
    this.addresses.push(addressForm)
  }


  /**
   * Edit a product in the modal
   * @param productIndex
   * @param addressIndex The index of the address currently being edited
   * @param addressType Delivery = 1, Pickup = 2
   */
  editProductModal(productIndex: number, addressIndex: number, addressType: number) {
    let addressFreights = this.addresses.at(addressIndex).get('addressFreights') as FormArray



    this.eventProxy.setEvent(new ProductModalEdit({
      addressType: addressType,
      addressIndex: addressIndex,
      products: this.products,
      formGroup: addressFreights.at(productIndex)
    }))
  }


  /**
   * Delete a product from the addressFreights array
   * @param productIndex The index of the product to delete
   * @param addressIndex The index of the address currently being edited
   * @param addressType Delivery = 1, Pickup = 2
   */
  deleteProduct(productIndex: number, addressIndex: number, addressType: number) {
    const addressArray = this.addresses.at(addressIndex)

    if (addressArray) {
      const addressFreights = addressArray.get('addressFreights') as FormArray
      if (addressFreights && addressFreights.at(productIndex)) {
        addressFreights.removeAt(productIndex)
      }
    }
  }

  displayFreightUnit(inputObj: any) {
    if (inputObj.name) {
      return inputObj.name
    }

    return inputObj
  }


  disableStuff(addressIndex: number, backVehicleOrEquipment: string) {
    const result: any = this.productTypeService.getFreightAmountLeft(this.addresses);

    // Check if arriveSummary or leaveSummary are empty or all values are zero
    const hasArriveSummary = result && Object.keys(result.arriveSummary).length > 0
      && Object.values(result.arriveSummary).some((value) => (value as number) > 0);

    const hasLeaveSummary = result && Object.keys(result.leaveSummary).length > 0
      && Object.values(result.leaveSummary).some((value) => (value as number) > 0);

    // Check if all matching properties in leaveSummary and arriveSummary cancel out
    const summariesCancelOut = Object.keys(result.leaveSummary || {}).every((key) => {
      const leaveValue = result.leaveSummary[key] || 0;
      const arriveValue = result.arriveSummary[key] || 0;
      return arriveValue - leaveValue === 0;
    });

    // Return true if summaries cancel out
    if (summariesCancelOut) {
      return true;
    }

    if (hasArriveSummary) {
      if (backVehicleOrEquipment === 'backVehicle') {
        if (this.selectedBackVehicles && this.selectedBackVehicles.length > 0) {
          return false;
        }
        return true;
      }

      if (backVehicleOrEquipment === 'equipment') {
        if (this.selectedTransportEquipment) {
          return false;
        }
      }

      return true;
    }

    if (hasLeaveSummary) {
      if (backVehicleOrEquipment === 'backVehicle') {
        if (this.selectedBackVehicles && this.selectedBackVehicles.length > 0) {
          return false;
        }
        return true;
      }

      if (backVehicleOrEquipment === 'equipment') {
        if (this.selectedTransportEquipment) {
          return false;
        }
      }

      return true;
    }

    return true;
  }
}


