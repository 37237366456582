import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class ModalComponent {
  @Input() title: string = 'Default Title'
  @Input() confirmButton: boolean = true
  @Input() cancelButton: boolean = true
  @Input() passedFormGroup: UntypedFormGroup | undefined;
  @Input() maxWidth: string = '500px'
  @Input() disabled: boolean = false

  @Output() confirmed = new EventEmitter()
  @Output() cancelled = new EventEmitter()

  isVisible: boolean = false


  open() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  confirm() {
    this.confirmed.emit()
    this.isVisible = false
  }

  cancel() {
    this.cancelled.emit()
    this.isVisible = false
  }
}
