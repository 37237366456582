import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject, distinctUntilChanged } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faStreetView, faTrash } from '@fortawesome/free-solid-svg-icons';

import { AddressModel } from '../address-widget/shared/address.model';
import { GeocodeService } from '../services/geocode.service';
import { TomtomMapPickerComponent } from 'src/app/tomtom-map-picker/tomtom-map-picker.component';
import { TomTomLatLng } from 'src/app/tomtom-map-picker/shared/tomtom.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-simple-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, TomtomMapPickerComponent, FormsModule, NgbTooltipModule, FaIconComponent],
  templateUrl: './simple-address.component.html',
  styleUrl: './simple-address.component.scss'
})
export class SimpleAddressComponent {
  @Input() addressIsRequired: boolean = true
  @Input() addressIndex: number = 0
  @Input() addresses: FormArray<FormGroup>;
  @Input() arriveBackVehicle: string[] = [];
  @Input() arriveEquipment: string[] = []
  @Input() leaveBackVehicle: string[] = []
  @Input() leaveEquipment: string[] = []
  @Input() createMode: any;
  @Input() customerAddresses: AddressModel[] | undefined
  @Input() freightTypeId = 0;
  @Input() inputFormGroup!: AbstractControl<any, any>
  @Input() reset = false;
  @Input() showDescription = true;
  @Input() showCoordinates = true;
  @Input() showEditButton = true;
  @Input() showLocationNameAndId = true;
  @Input() showFreightSection = true;
  @Input() showFreightType = true;
  @Input() showUpdateAndCancelButtons = true
  @Input() showCreateButton: boolean = false
  @Input() readOnly: boolean = true
  @Input() showBillingAddress: boolean = false
  @Input() singleAddress: boolean = false

  @Output() cancelCreate = new EventEmitter();
  @Output() createAddress = new EventEmitter()
  @Output() delete = new EventEmitter()
  @Output() duplicateEmit = new EventEmitter<any>()
  @Output() editAddress = new EventEmitter()
  @Output() removeAddressAtIndexEmit = new EventEmitter<number>()
  @Output() swapEmit = new EventEmitter<any>()
  @Output() showStreetview = new EventEmitter();
  @Output() arriveBackVehiclesEmitter = new EventEmitter<any>()
  @Output() updateReadOnlyEmitter = new EventEmitter<boolean>()

  addressSearch: string[] = []
  addressSearchUpdate = new Subject<string>()
  searchResults: any = []
  currentLat: number = 55.850849
  currentLon: number = 9.845612


  faStreetView = faStreetView
  faTrash = faTrash


  constructor(private formBuilder: UntypedFormBuilder, private geocoder: GeocodeService, private http: HttpClient) {
    this.addresses = this.formBuilder.array([])

    this.addressSearchUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getValue(value)
      })
  }



  addAddress() {
    this.addresses.push(this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: [''],
      zipcode: ['', Validators.required],
      street: ['', Validators.required],
      latitude: [0, this.singleAddress == false ? Validators.required : ''],
      longitude: [0, this.singleAddress == false ? Validators.required : ''],
      description: '',
      locationName: ['', this.singleAddress == false ? Validators.required : ''],
      locationId: ['', this.singleAddress == false ? Validators.required : '']
    }))
  }


  removeAddressAtIndex(addressIndex: any) {
    // existing address
    if (addressIndex.value.id) {
      this.addresses.removeAt(addressIndex.value.id)
      this.removeAddressAtIndexEmit.emit(addressIndex.value.id)
    } else { // address currently being made
      this.addresses.removeAt(addressIndex.value.street)
    }
  }


  /**
   * Updates the address by picking a spot on the map
   * @param input
   * @param addressIndex
   */
  updateCoordinates(input: any, addressIndex: number) {
    this.geocoder.reverseGeocodeAddress(input.lat, input.lng).subscribe(response => {
      this.addresses.at(addressIndex).patchValue({
        city: response.city,
        country: response.country,
        state: response.state,
        zipcode: response.zipcode,
        street: response.street,
        latitude: response.latitude,
        longitude: response.longitude,
        description: response.description
      })
    })
  }


  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }


  getValue(value: string) {
    this.http.get(
      'https://api.tomtom.com/search/2/search/' +
      value + '.json?' +
      'lat=' + this.currentLat + '&' +
      'lon=' + this.currentLon + '&' +
      'minFuzzyLevel=1&' +
      'maxFuzzyLevel=2&' +
      'view=Unified&' +
      'relatedPois=off&' +
      'returnPostalCodeForPOI=true&' +
      'typeahead=true&' +
      'key=' + environment.tomtom.key)
      .subscribe((data: any) => (this.searchResults = data['results']));
  }


  fillAddressForm(result: any, address: FormArray, index: number) {
    this.addressSearch[index] = '' // empty the first search field

    if (result.address !== undefined) {

      let city = "";

      if (result.address.municipality !== undefined) city = result.address.municipality;
      if (result.address.municipalitySubdivision !== undefined) city = result.address.municipalitySubdivision;

      if (result.address.streetName !== undefined) address.at(index).patchValue({ street: result.address.streetName });
      if (result.address.streetName !== undefined && result.address.streetNumber !== undefined) address.at(index).patchValue({ street: result.address.streetName + " " + result.address.streetNumber });
      if (result.address.municipality !== undefined) address.at(index).patchValue({ city: city });
      if (result.address.country !== undefined) address.at(index).patchValue({ country: result.address.country });
      if (result.address.postalCode !== undefined) address.at(index).patchValue({ zipcode: result.address.postalCode });
      if (result.address.countrySubdivision !== undefined) address.at(index).patchValue({ state: result.address.countrySubdivision });
    }

    if (result.position !== undefined) {
      address.at(index).patchValue({ longitude: result.position.lon, latitude: result.position.lat });
    }
    this.searchResults = null
    this.editAddress.emit(address.value as AddressModel)
  }



  checkIfUndefined() {
    return typeof this.addresses === 'undefined'
  }


  emitStreetview(addressIndex: number) {
    const addressForm = this.addresses.at(addressIndex) as FormGroup

    if (addressForm.value.latitude != undefined && addressForm.value.longitude != undefined) {
      let outputModel: TomTomLatLng = { lat: addressForm.value.latitude, lng: addressForm.value.longitude };
      this.showStreetview.emit(outputModel);
    }
  }
}
