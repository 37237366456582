import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlecBasicReport, ReportItems, UserIdList } from './report.model';
const url = environment.baseUrl + '/V1/Report'
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient) { }


  getReportList(parameters: any): Observable<any> {
    return this.http.get(`${url}/List`, {
      params: parameters
    });
  }


  getReportHtml(id: any): Observable<any> {
    return this.http.get(`${url}/Html/${id}`, { responseType: 'text' });
  }


  downloadPdf(id: any): Observable<any> {
    return this.http.get(`${url}/Pdf/${id}`, { responseType: 'blob' }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  }


  downloadCsv(id: any): Observable<any> {
    return this.http.get(`${url}/Csv/${id}`, { responseType: 'blob' }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'text/csv' });
      }));
  }

  downloadReportZip(ids: number[]): Observable<any> {
    return this.http.post(`${url}/Pdf`, ids, { responseType: "blob" }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/zip' });
      }));
  }


  getTourReport(id: number): any {
    return this.http.post(`${url}/Tour/${id}`, null, { responseType: 'blob' }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  }


  getReportAsPdf(pdfstring: any, pdfname: any): Observable<any> {
    return this.http.post(`${url}/Generate/Pdf`, { pdfName: pdfname, pdfstring: pdfstring }, { responseType: 'blob' })
      .pipe(map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        }));
  }


  getUsersReportAsHtml(userIds: UserIdList, start: any, end: any): Observable<any> {
    //return this.http.post(`${url}/Salary/${start}/${end}/Html`, null, { params: { userId: id }, responseType: 'text'});
    return this.http.post(`${url}/Salary/${start}/${end}/Html`, userIds, { responseType: 'text' });
  }


  getTourReportAsHtml(tourIds: UserIdList): Observable<any> {
    return this.http.post(`${url}/Tour/Html`, tourIds, { responseType: 'text' });
    //  return this.http.post(`${url}/Tour/${id}/Html`, null, {responseType: 'text'});
  }


  getDayReportAsHtml(start: any, customerId: any, userIds: UserIdList): Observable<any> {
    return this.http.post(`${url}/Day/${start}/${customerId}/Html`, userIds, { responseType: 'text' });
    //  return this.http.post(`${url}/Tour/${id}/Html`, null, {responseType: 'text'});
  }


  getGlecReportAsHtml(tourIds: UserIdList): Observable<any> {
    return this.http.post(`${url}/Glec/Html`, tourIds, { responseType: 'text' });
    //return this.http.post(`${url}/Glec/Html`, null, {responseType: 'text'});
  }

  getCommDocReportAsHtml(): Observable<any> {
    return this.http.post(`${url}/CommDoc/Html`, { responseType: 'text' });
    //return this.http.post(`${url}/Glec/Html`, null, {responseType: 'text'});
  }

  getGlecBasicReportAsHtml(id: number, model: GlecBasicReport): Observable<any> {
    return this.http.post(`${url}/GlecBasic/${id}/Html`, model, { responseType: 'text' });
  }


  getGlecDistReportAsHtml(tourIds: UserIdList): Observable<any> {
    return this.http.post(`${url}/GlecDist/Html`, tourIds, { responseType: 'text' });
    //return this.http.post(`${url}/GlecDist/Html`, null, {responseType: 'text'});
  }

  deleteSelectedReports(ids: number[]): Observable<any> {
    return this.http.delete(`${url}`, { body: ids });
  }

  generateDownloadLink(res: Blob) {
    const blobUrl = window.URL.createObjectURL(res);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `reports.zip`);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  }
}
