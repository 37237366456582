import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { } from "@fullcalendar/angular";
import { EventModel } from '../calendar/shared/event.model';
import { TimeStatementService } from './shared/time-statement.service';
import tippy from "tippy.js";
import { UserService } from "../../users/shared/user.service";
import { TourType } from "../../constants";
import { UserModel, salaryWorkDayModel } from "../../users/shared/user.model";
import * as moment from "moment";
import
  {
    AgreedWeekDayModel
  } from "./shared/agreed-week-schedule.model";
import { ColTotals, TableTotalsModel } from "./shared/table-totals.model";
import { NotificationService } from "../services/notification.service";

import { FormGroup } from '@angular/forms';
import { TourEventModel, TourSimpleModel } from 'src/app/tours/shared/tour.model';
import { SharedService } from '../services/shared.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FreezeSalaryWeekData, FreezeSalaryWeekModel } from '../../users/shared/user-agreed-week.model';
import { faSnowflake, faUserEdit, faCheck } from '@fortawesome/free-solid-svg-icons';
import { CalendarOptions } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-statement',
  templateUrl: './time-statement.component.html',
  styleUrls: ['./time-statement.component.scss'],
  providers: [DatePipe]
})
export class TimeStatementComponent implements OnInit, OnChanges
{
  showWithBreaskSubtracted: boolean = false;
  frozen = faSnowflake;
  userPen = faUserEdit;
  checkMark = faCheck;
  userData: UserModel | undefined;
  events: any[] = [];
  btnTap = TourType.Inland;

  loading: boolean = false;
  start: any;
  year: any;
  end: any;
  language: any;

  workDays: salaryWorkDayModel[] = [];

  salaryResponse: any;

  get tourTypes(): typeof TourType
  {
    return TourType;
  }

  @Input()
  user: UserModel | undefined;

  //calendarOptions: CalendarOptions = {
  //  schedulerLicenseKey: '0799297633-fcs-1642062629',
  //  initialView: 'timeGridWeek',
  //  allDaySlot: false,
  //  locale: 'da-dk',
  //  weekNumbers: true,
  //  //eventTextColor: '#c7efff',
  //  weekText: 'Uge ',
  //  displayEventTime: true,
  //  firstDay: 1,
  //  buttonText: { today: 'I dag' },
  //  dayHeaderFormat: {
  //    weekday: 'long'
  //  },
  //  eventMinHeight: 1,
  //  events: (fetchInfo, successCallback, failureCallback) =>
  //    this.fetchData(fetchInfo),
  //  eventDidMount: (info) => this.onEventDidMount(info)
  //};

  calendarOptions: CalendarOptions = {
    plugins: [
      resourceTimelinePlugin,
      resourceTimeGridPlugin,
      bootstrapPlugin,
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin
    ],
    schedulerLicenseKey: '0264157724-fcs-1705169645',
    initialView: 'timeGridWeek',
    allDaySlot: false,
    locale: 'da-dk',
    weekNumbers: true,
    //eventTextColor: '#c7efff',
    weekText: this.translate.instant('timeStatement.weekWithWhiteSpace'),
    displayEventTime: true,
    firstDay: 1,
    buttonText: { today: this.translate.instant('timeStatement.today') },
    dayHeaderFormat: {
      weekday: 'long'
    },
    eventMinHeight: 1,
    events: (fetchInfo: any, successCallback: any, failureCallback: any) =>
      this.fetchData(fetchInfo),
    eventDidMount: (info: any) => this.onEventDidMount(info)
  };
  constructor(
    public timeStatementService: TimeStatementService,
    private datePipe: DatePipe,
    private userService: UserService,
    private notifyService: NotificationService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) { }

  async getLanguage()
  {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void>
  {
    await this.getLanguage()

    this.calendarOptions.events = (fetchInfo: any) => this.fetchData(fetchInfo);
    this.calendarOptions.eventDidMount = (info: any) => this.onEventDidMount(info);
  }


  ngOnChanges(changes: SimpleChanges): void
  {
    this.calendarOptions.events = (fetchInfo: any) => this.fetchData(fetchInfo);
    this.calendarOptions.eventDidMount = (info: any) => this.onEventDidMount(info);
  }


  validateDates(group: FormGroup)
  {
    const datestart = group.controls['datestart'].value
    const dateend = group.controls['dateend'].value
    const timestart = group.controls['timestart'].value;
    const timeend = group.controls['timeend'].value;

    if (moment(dateend).isBefore(moment(datestart)))
    {
      group.controls['dateend'].setErrors({ mustBeLater: true });
      return null;
    }

    if (moment(datestart).isSame(moment(dateend)) && moment(timeend).isBefore(moment(timestart)))
    {
      group.controls['dateend'].setErrors({ mustBeLater: true });
      return null;
    }

    return {
      mustBeLater: false
    };
  }


  mapTourEvents(tours: TourSimpleModel[], resourceId: string): EventModel[]
  {
    let events: any[] = [];
    tours.forEach(tour =>
    {
      events = [...events, ...tour.events.map((event: TourEventModel) => ({
        id: event.id.toString(),
        resourceId: resourceId,
        start: event?.actualStartDate,
        end: event?.actualEndDate,
        classNames: ['unclickable tourevent'],
        title: event?.description,
        color: 'rgba(0, 0, 0, 0.3)'
      }))];
    });
    return events;
  }


  groupBy(xs: any, f: any)
  {
    return xs.reduce((r: any, v: any, i: any, a: any, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }


  async fetchData(e: any): Promise<EventModel[]>
  {
    if (!this.user) return [];
    this.start = this.datePipe.transform(e.start, 'yyyy-MM-dd');
    this.end = this.datePipe.transform(e.end, 'yyyy-MM-dd');
    this.year = this.datePipe.transform(e.start, 'yyyy');

    const agreementId: number = this.btnTap === TourType.Inland ? 0 : 1;

    this.salaryResponse = await this.userService.getUserWithTimeRegistrationsSalaryFoundation(this.user.id, this.start, this.end, agreementId).toPromise();
    this.timeStatementService.MapFromSalaryResponseToTableData(this.salaryResponse, this.btnTap === TourType.Export);
    //this.timeStatementService.MapFromSalaryResponseToTableData(this.salaryResponse, this.btnTap === TourType.Export,this.datePipe.transform(e.start, 'yyyy-MM-dd'));

    this.userData = await this.userService.getUserWithTimeRegistrations(this.user?.id, this.start, this.end).toPromise();
    let calculatedData = this.timeStatementService.MapFromSalaryResponseToCalenderEvents(this.salaryResponse);
    this.timeStatementService.FetchFrozenData(this.btnTap === TourType.Export, this.start, this.end, this.user.id);

    if (!calculatedData) return [];
    let userdataTours: EventModel[] = [];
    if (this.userData)
    {
      userdataTours = this.userData.tours
        .filter(t => this.btnTap === TourType.Inland ? t.type === this.tourTypes.Inland : t.type === this.tourTypes.Export).map(tour => ({
          id: tour.id.toString(),
          resourceId: this.userData?.id.toString(),
          title: tour.isSpecial && tour.type === TourType.Export ? 'Special' : '',
          color: 'transparent',
          borderColor: tour.isSpecial ? 'rgb(46, 82, 97)' : 'white',
          start: tour?.actualStartDate !== null ? this.addMinutesToDate(tour.actualStartDate, 5) : tour?.expectedStartDate,
          end: tour?.actualEndDate !== null ? tour.actualEndDate : tour?.expectedEndDate
        }))
    }

    const events: EventModel[] = [...calculatedData.events, ...userdataTours];
    this.setAbroadTableData(calculatedData.weekNumber);

    return events;
  }


  addMinutesToDate(date: Date, minutes: number): Date
  {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + minutes);
    return newDate;
  }

  today(): string
  {
    const today = new Date();
    return <string>this.datePipe.transform(today, 'yyyy-MM-dd');
  }

  setAbroadTableData(weekNumber: number): void
  {
    let tabletotals = this.timeStatementService.getTableTotals();

    this.userData?.tours.filter(t => t.type === TourType.Export && moment(t.actualEndDate).isoWeek() === weekNumber)
      .forEach(t =>
      {
        switch (moment(t.actualEndDate).isoWeekday())
        {
          case 1: {
            if (t.isSpecial)
              tabletotals.mo.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 2: {
            if (t.isSpecial)
              tabletotals.tu.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 3: {
            if (t.isSpecial)
              tabletotals.we.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 4: {
            if (t.isSpecial)
              tabletotals.th.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 5: {
            if (t.isSpecial)
              tabletotals.fr.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 6: {
            if (t.isSpecial)
              tabletotals.sa.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
          case 7: {
            if (t.isSpecial)
              tabletotals.su.special += this.calculateSpecialDuration(t.actualStartDate, t.actualEndDate);
            break;
          }
        }
      });
  }
  getUserWeekDay(i: number): AgreedWeekDayModel
  {
    if (!this.user) return { normalTimeMinutes: 0 };

    return { normalTimeMinutes: Math.floor(Number(this.user.userMeta?.hoursAgreement?.split(';')[i].replace(',', '.')) * 60) };
  }

  calculateSpecialDuration(start: Date | any, end: Date | any): number
  {
    const startMoment = moment(start);
    let endMoment = moment(end);

    return moment.duration(endMoment.diff(startMoment)).asMinutes();
  }

  toHours(minutes: number | undefined): string
  {
    if (!minutes) return '0';

    const value = minutes / 60;

    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace('.', ',');
  }

  roundTo(num: number, places: number)
  {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };


  onChangeCalendar(): void
  {
    this.calendarOptions.events = (fetchInfo: any) => this.fetchData(fetchInfo);
    this.calendarOptions.eventDidMount = (info: any) => this.onEventDidMount(info);
  }

  async update(): Promise<void>
  {
    this.loading = true;


    this.timeStatementService.getTableTotals().reset();
    this.loading = false;
  }



  canCreateUserAgreedWeek(): boolean
  {
    //do not allow to refreeze
    if (this.btnTap === TourType.Inland && this.timeStatementService.frozenStatusVisual.inlandHasRevisions)
      return false;

    if (this.btnTap === TourType.Export && this.timeStatementService.frozenStatusVisual.exportHasRevisions)
      return false;

    // Only allow to freeze the work week if it's a historic week. Eg you can not freeze week 7 until week 8
    const tableTotals = this.timeStatementService.getTableTotals();

    const currentYear = moment().format('yyyy');
    if (Number(currentYear) > Number(tableTotals.year))
      return true;

    const currentWeek = moment().format('W');
    return tableTotals.week != 0 && tableTotals.week < +currentWeek;
  }

  createUserAgreedWeek(): void
  {
    let tableTotals = this.timeStatementService.getTableTotals();

    if (!this.user) return;

    let firstDay = new Date(this.start);

    if (firstDay.getDay() === 0)
    {
      firstDay.setDate(firstDay.getDate() - 1);
    }

    while ((firstDay.getDay() - 1) > 0)
    {
      firstDay.setDate(firstDay.getDate() - 1);
    }

    let lastDay = new Date(this.start);
    while (lastDay.getDay() !== 0)
    {
      lastDay.setDate(lastDay.getDate() + 1);
    }

    const currentWeek = Number(moment(this.start).format('W'));

    const data: FreezeSalaryWeekData = {
      userId: this.user.id,
      total: tableTotals.calculateTotal(false, false),
      totalSubtractedBreaks: tableTotals.totalSubtractedBreaks,
      week: currentWeek,
      fromDate: tableTotals.from,
      toDate: tableTotals.to,
      year: this.year,
      revision: tableTotals.revision,
      isAbroad: this.btnTap === TourType.Export,
      mondayJson: JSON.stringify(tableTotals.mo),
      tuesdayJson: JSON.stringify(tableTotals.tu),
      wednesdayJson: JSON.stringify(tableTotals.we),
      thursadayJson: JSON.stringify(tableTotals.th),
      fridayJson: JSON.stringify(tableTotals.fr),
      saturdayJson: JSON.stringify(tableTotals.sa),
      sundayJson: JSON.stringify(tableTotals.su)
    }

    let transferTotals = this.timeStatementService.getTansferedTotals();
    let transfers: FreezeSalaryWeekData[] = [];
    transferTotals.forEach(t =>
    {
      if (this.user)
      {
        let transfer: FreezeSalaryWeekData = {
          userId: this.user.id,
          total: t.calculateTotal(false, false),
          totalSubtractedBreaks: t.totalSubtractedBreaks,
          week: t.week,
          fromDate: t.from,
          toDate: t.to,
          year: t.year,
          revision: t.revision,
          isAbroad: this.btnTap === TourType.Export,
          mondayJson: JSON.stringify(t.mo),
          tuesdayJson: JSON.stringify(t.tu),
          wednesdayJson: JSON.stringify(t.we),
          thursadayJson: JSON.stringify(t.th),
          fridayJson: JSON.stringify(t.fr),
          saturdayJson: JSON.stringify(t.sa),
          sundayJson: JSON.stringify(t.su),
        }
        transfers.push(transfer);
      }
    })

    let model: FreezeSalaryWeekModel = {
      weekModel: data,
      transferedWeekModels: transfers
    }

    this.userService.FreezeWeek(model).subscribe(x =>
    {
      tableTotals.revision = x.revision;
      this.notifyService.successMessage(this.language?.message?.weekSchemeMarkedFrozen);
      if (model.weekModel.isAbroad)
      {
        this.timeStatementService.frozenStatusVisual.exportHasRevisions = true;
      } else
      {
        this.timeStatementService.frozenStatusVisual.inlandHasRevisions = true;
      }
    });
  }

  public greaterThan(t1: number, t2: number)
  {
    return t1 > t2;
  }

  selectedDay: ColTotals = new ColTotals();
  modal?: NgbModalRef;
  dayName: string = "";
  selectedTableTotal: TableTotalsModel = this.timeStatementService.getTableTotals();



  public openEdit(editwindow: TemplateRef<any>, day: string, tabletotals: TableTotalsModel)
  {
    this.selectedTableTotal = tabletotals;
    //do not allow to refreeze
    if (this.btnTap === TourType.Inland && this.timeStatementService.frozenStatusVisual.inlandHasRevisions)
    {
      this.notifyService.warningMessage(this.translate.instant('message.fronzenWageHoursCannotBeCorrected'));
      return;
    }

    if (this.btnTap === TourType.Export && this.timeStatementService.frozenStatusVisual.exportHasRevisions)
    {
      this.notifyService.warningMessage(this.translate.instant('message.fronzenWageHoursCannotBeCorrected'));
      return;
    }

    if (!this.canCreateUserAgreedWeek())
    {
      this.notifyService.warningMessage(this.translate.instant('message.currentWeeksCannotBeFrozen'));
      return;
    }

    if (day == 'mo')
    {
      this.selectedDay = this.selectedTableTotal.mo;
      this.dayName = this.translate.instant('timeStatement.days.monday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'tu')
    {
      this.selectedDay = this.selectedTableTotal.tu;
      this.dayName = this.translate.instant('timeStatement.days.tuesday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'we')
    {
      this.selectedDay = this.selectedTableTotal.we;
      this.dayName = this.translate.instant('timeStatement.days.wednesday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'th')
    {
      this.selectedDay = this.selectedTableTotal.th;
      this.dayName = this.translate.instant('timeStatement.days.thursday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'fr')
    {
      this.selectedDay = this.selectedTableTotal.fr;
      this.dayName = this.translate.instant('timeStatement.days.friday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'sa')
    {
      this.selectedDay = this.selectedTableTotal.sa;
      this.dayName = this.translate.instant('timeStatement.days.saturday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else if (day == 'su')
    {
      this.selectedDay = this.selectedTableTotal.su;
      this.dayName = this.translate.instant('timeStatement.days.sunday');
      this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
    }
    else
    {
      this.selectedDay = new ColTotals();
      this.dayName = "";
    }
  }

  onEventDidMount(e: any)
  {
    tippy(e.el, {
      allowHTML: true,
      content: '<span>' + `${this.datePipe.transform(e.event.start, 'HH:mm')} - ${this.datePipe.transform(e.event.end, 'HH:mm')}` + '</span>',
      placement: 'top-start'
    });

    window.dispatchEvent(new Event('resize'));
  }
  numberOnly(event: any): boolean
  {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 45)
    {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57))
    {
      return false;
    }
    return true;

  }
  isRadioChecked(e: any)
  {
    let labels = document.querySelectorAll('.inland-export-container > .btn-outline-default');
    labels.forEach(label =>
    {
      label.classList.remove('active');
    });
    if (e.target.tagName == 'LABEL')
    {
      e.target.classList.add('active');
    }
    if (e.target.tagName == 'INPUT')
    {
      e.target.parentElement.classList.add('active');
    }
  }
}
