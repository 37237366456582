<div class="message-list-component">
  @if (this.messageList && this.messageList.length >= 1) {
    @for (message of this.messageList | slice:(this.page - 1) * this.pageSize:this.page * this.pageSize; track $index) {
      <div class="d-flex message-list-item flex-nowrap p-1" [ngClass]="{ 'unread': !message.isRead }" (click)="this.selectMessage(message)">
        <div class="col-4">
          {{ message.type }}
        </div>
        <div class="col-4">
          {{ this.readableDate(message.timestamp) }}
        </div>
        <div class="col-4">
          {{ message.sender?.fullname }}
        </div>
      </div>
    }
  }
  @else {
    <div class="d-flex message-list-item flex-nowrap p-1">
      <div class="col">
        {{ 'messaging.nomessages' | translate }}
      </div>
    </div>
  }

  <div class="message-list-pagination mt-2 mb-3">
    @if (this.messageList && this.messageList.length >= 1) {
      <ngb-pagination [collectionSize]="this.messageList.length" [(page)]="page" [pageSize]="pageSize"></ngb-pagination>
    }
  </div>

  <div [ngClass]="{ 'd-none': !this.showMessageContent }" class="message-list-content bg-body-tertiary p-4">
    <span class="mb-5 italic">{{ this.readableDate(this.selectedMessageContent?.timestamp) }}</span>

    <br><br>

    {{ this.selectedMessageContent?.message }}
    <br>
    - {{ this.selectedMessageContent?.sender?.fullname }}
  </div>
</div>
