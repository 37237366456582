import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TokenService } from "../../services/token.service";
import { UserService } from "../../users/shared/user.service";
import { CompanyModel, GroupModel } from "../../users/shared/user.model";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EventProxyService, UpdateGroup } from 'src/app/shared/services/event-proxy.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  //groups: CompanyModel[] | undefined;
  companies: GroupModel[] = [];
  userRole: string = ''
  isMobile = false;
  selectedCompany = false;



  constructor(private route: ActivatedRoute, private router: Router, private tokenService: TokenService,
    private userService: UserService, private oidc: OidcSecurityService,
    private eventProxyService: EventProxyService, private deviceService: DeviceDetectorService,
        private renderer: Renderer2, private el: ElementRef) {
  }

  ngOnInit(): void {
    var g = this.tokenService.getGroups();

    this.userRole = this.tokenService.getUserRole()

    // change to the role you wish to test with
    // this.userRole = 'Report'

    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {

      this.isMobile = true;
      this.renderer.removeClass(document.body, 'run-my-script');
 

      const divToRemove = this.el.nativeElement.querySelector('#beacon-container');
 

      if (divToRemove) {
        this.renderer.removeChild(this.el.nativeElement, divToRemove);
      }


    }
 
  
    if (g.length === 0) {
      this.router.navigate(['error']);
    }
    else if (g.length === 1) {

    this.tokenService.setCompany(g[0]);
      this.selectedCompany = true;

      if (!this.isMobile) {
      
      this.redirect();

      }
      
    } else {
      this.companies = g;
    }
  }

  navigateTracker() {
   // window.showHelpscout = false; 
    return this.router.navigate(['trackerSimple'])
  }

  navigatePortal() {

      this.redirect();


  }

  redirect() {
    // Temp fix until Kiosk role is implmented!
    var email = this.tokenService.getUserEmail();
    if (email == "EHKIOSK@trasolu.dk") {
      return this.router.navigate(['acr']);
    } else {
      if (this.userRole == 'Report' || this.userRole.includes('Report')) {
        return this.router.navigate(['reports'])
      }

      if (this.userRole == 'Employee' || this.userRole.includes('Employee')) {
        return this.router.navigate(['employee'])
      }


      return this.router.navigate(['tours']);
    }
  }


  setCompany(company: GroupModel) {
    this.tokenService.setCompany(company);

    this.eventProxyService.setEvent(new UpdateGroup(null))

    // remove session id on customer change
    if (sessionStorage.getItem('groupId')) {
      sessionStorage.removeItem('groupId')
    }
    this.selectedCompany = true;
    if (!this.isMobile) this.redirect();
    

    if(sessionStorage.getItem('urlAfterStart') && !this.isMobile) {
      this.router.navigate([sessionStorage.getItem('urlAfterStart')]);
    }
  }
  //{
  //  //this.tokenService.setCompanyGuid(company.guid);
  //  //this.tokenService.setCompanyName(company.name);
  //  //this.tokenService.companyName.next(company.name);
  //  //this.groups = undefined;


  //  var email = this.tokenService.getUserEmail();

  //  // Temp fix until Kiosk role is implmented!
  //  if (email == "EHKIOSK@trasolu.dk")
  //  {
  //    this.router.navigate(['acr']);
  //  } else
  //  {
  //    this.router.navigate(['tours']);
  //  }

  //  // FIX FOR ACR V2
  //  //this.router.navigate(['acr']);
  //}


}
