<div class="row m-0 p-0 usageBox" id="usageBox">
  <div class="col-12 m-1"><span class="usageTitle"> {{'reports.glec.view.averageCO2eEmittedOnTask' | translate}} <br></span></div>
  <div class="col-6 text-left"><span class="infoText">
      {{consumption}} {{'reports.glec.view.literConsumption' | translate}} <br>
      {{co2e}} {{'reports.glec.view.kgCO2e' | translate}}<br>
      {{co2ePer}} {{'reports.glec.view.kgCO2eSlash' | translate}}{{unit}}</span>
  </div>
  <div class="col-6 text-center">

  </div>
</div>
<button type="button" (click)="generateImage()">{{'reports.glec.view.createImage' | translate}}</button>
