<p>{{signInTranslate}}</p>
<div class="background">
    <div class="spinner-border content text-dark" role="status">
      <span class="sr-only">{{signInTranslate}}</span>
    </div>

</div>
<style>
  .background {
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    text-align: center;
  }

  .content {
    width: 6rem;
    height: 6rem;
    position: absolute; /*Can also be `fixed`*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }


</style>
<!--<div class="container">
    <div class="col-md-12 center">
      <h5 style="margin-left: 30px">title</h5>
      <ng-container class="row" *ngFor="let group of companies">
        <div class="col-md-12">
          <button class="btn btn-primary" style="width: 100%; margin: 5px"
            (click)="setCompany(group)">{{group.name}}</button>
        </div>
      </ng-container>
    </div>
  </div>-->
