



<div class="col-md-12 center" *ngIf="!selectedCompany">
  <h5 style="margin-left: 30px">{{'start.title' | translate}}</h5>
  <ng-container class="row" *ngFor="let group of companies">
    <div class="col-md-12">
      <button class="btn btn-primary" style="width: 100%; margin: 5px" (click)="setCompany(group)">{{group.name}}</button>
    </div>
  </ng-container>


</div>

 


<div class="col-md-12 centerMobile login-page login-box" *ngIf="isMobile && selectedCompany">

  
  <div class="login-logo">
    <img src="/assets/images/trasolu_logo.svg" alt="Trasolu Logo" class="img-fluid" style="opacity: .8;">

</div>

  <h3 style="margin-left: 30px">{{'start.titleMobile' | translate}}</h3>
  <h5 style="margin-left: 30px">{{'start.subTitleMobile' | translate}}</h5>

<div class='row'>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body  login-card-body">
          <h5 class="card-title">{{'navigation.tracker' | translate}}</h5>
          <p class="card-text">{{'tracker.desc' | translate}}</p>
          <a (click)="navigateTracker()" class="btn btn-primary">{{'common.navigate' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body  login-card-body">
          <h5 class="card-title">{{'navigation.fullPage' | translate}}</h5>
          <p class="card-text">{{'portal.desc' | translate}}</p>
          <a (click)="navigatePortal()" class="btn btn-primary">{{'common.navigate' | translate}}</a>
        </div>
      </div>
    </div>

</div>
<img class="watermark" src="/assets/images/trasolu_watermark.svg">
</div>
