import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as moment from 'moment'
import { firstValueFrom } from 'rxjs'
import { MessagingService } from './messaging.service'
import { EventProxyService } from 'src/app/shared/services/event-proxy.service'


export interface MessageContent {
  id?: number,
  message: string,
  userId?: number | null,
  frontVehicleId?: number | null,
  showInApp?: boolean,
  isRead?: boolean,
  type?: any,
  sender?: MessageSender | null,
  timestamp?: string
}

export interface MessageSender {
  id?: number,
  fullname?: string
}



@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrl: './message-list.component.scss'
})
export class MessageListComponent implements OnInit {
  @Input() messageList: MessageContent[] = []

  endDate: moment.Moment = moment()
  showMessageContent: boolean = false
  selectedMessageContent: MessageContent | null = null
  page: number = 1
  pageSize: number = 10

  translations = {
    hoursAgo: '',
    singleHourAgo: '',
    minutesAgo: '',
    singleMinuteAgo: '',
    now: ''
  }

  constructor(private translate: TranslateService, private proxyEventService: EventProxyService, private messagingService: MessagingService) { }

  async ngOnInit() {
    await this.getTranslations()
  }


  async getTranslations() {
    try {
      this.translations.hoursAgo = await firstValueFrom(this.translate.get('messaging.read.hoursAgo'))
      this.translations.singleHourAgo = await firstValueFrom(this.translate.get('messaging.read.singleHourAgo'))
      this.translations.minutesAgo = await firstValueFrom(this.translate.get('messaging.read.minutesAgo'))
      this.translations.singleMinuteAgo = await firstValueFrom(this.translate.get('messaging.read.singleMinuteAgo'))
      this.translations.now = await firstValueFrom(this.translate.get('messaging.read.now'))
    } catch (error) {
      console.error('Error: ', error)
    }
  }


  getMessages() {

  }


  /**
   * Clicking a message in the list will show the message content and mark the message as read
   * @param message The selected message
   */
  selectMessage(message: MessageContent) {
    if (message.id && !message.isRead) {
      message.isRead = true

      this.messagingService.updateReadStatus(message.id).subscribe(() => { })
    }


    this.selectedMessageContent = message
    this.showMessageContent = true
  }

  /**
   * Toggle the message content view
   * @param index The index of the message in the list
   */
  showContent(index: number) {
    this.showMessageContent = true
  }

  /**
   * Display a more human readable date, displaying "now", "minutes ago", "hours ago" and the full date
   * @param date The date string to convert to a readable format
   * @returns
   */
  readableDate(date: string | undefined) {
    if (date) {
      let duration = moment.duration(this.endDate.diff(moment(date)))
      let hours = duration.asHours()
      let minutes = duration.asMinutes()

      if (hours >= 24) {
        return moment(date).format('HH:mm - DD/MM/YYYY')
      }

      if (hours >= 1) {
        if (Math.floor(hours) === 1) return this.translations.singleHourAgo.replace('{number}', Math.floor(hours).toString())
        else return this.translations.hoursAgo.replace('{number}', Math.floor(hours).toString())
      }

      if (hours < 1 && minutes > 1) {
        if (Math.floor(duration.asMinutes()) === 1) return this.translations.singleMinuteAgo.replace('{number}', Math.floor(duration.asMinutes()).toString())
        else return this.translations.minutesAgo.replace('{number}', Math.floor(duration.asMinutes()).toString())
      }

      if (minutes <= 1) {
        return this.translations.now
      }
    }

    return ''
  }
}
