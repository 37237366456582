<!-- <div class="product-type-picker product-type-picker--component">
  <button class="btn btn-primary btn-sm" (click)="this.openSesame()">
    @if (this.formGroup.get('freight')?.value) {
      <span>[Ændre godstyper]</span>
    }
    @else {
      <span>[Vælg godstyper]</span>
    }
  </button>
</div> -->

<app-modal #productTypeModalComponent [maxWidth]="'900px'" [disabled]="!this.canAdd" (confirmed)="this.confirmAndEmitForm()" (cancelled)="this.closeAndReset()">
  <div class="modal-body">
    <form [formGroup]="this.formGroup">
      <div class="row">
        <!-- Product Type -->
        <div class="col-6 form-group">
          <label for="productType">{{ 'logbooktype.metadata.productid' | translate }}</label>
          <ejs-combobox
            #productType
            id='productTypes'
            [dataSource]='this.products'
            [fields]="{ text: 'name' }"
            [allowFiltering]="true"
            [value]="this.formGroup.get('productType')?.value.name"
            (select)="this.setProductType($event)"
          ></ejs-combobox>
        </div>
      </div>


      <div class="row">
        <!-- Product Type -->
        <div class="col-6 form-group">
          <label for="productType">
            {{ 'tour.freightTypes.amount' | translate }}
          </label>
          <input type="number" class="form-control" formControlName="amount" [min]="0" required>
        </div>

        <!-- Freight Unit -->
        <div *ngIf="this.formGroup.get('amount')?.value" class="col-4 form-group">
          <label for="freightUnitId">
            {{ 'tour.freightTypes.freightType' | translate }}
          </label>
          <ejs-combobox
            id='freightUnitId'
            [dataSource]='this.selectedProduct.freightUnit'
            [fields]="{ text: 'name', value: 'id' }"
            [value]="this.formGroup.get('freightUnit')?.value"
            (select)="this.setFreightType($event)"
          ></ejs-combobox>
        </div>
      </div>


      <!-- Surcharges -->
      <div class="row align-items-center">
        <div class="col-12 pb-1 pt-4">
          <h4 class="mb-0">{{ 'surcharge' | translate }}</h4>
        </div>

        <div class="col-12 pb-2">
          <div *ngFor="let surcharge of this.surcharges; let index = index" class="row form-group">
            <!-- Existing surcharges -->
             <div class="col-11">
               <ejs-combobox
                  [dataSource]="surchargesList()"
                  [fields]="{ text: 'name', value: 'id' }"
                  [value]="this.formGroup.get('surcharges')?.value[index]?.name"
                  (select)="setSurcharge($event, index)">
               </ejs-combobox>
             </div>

             <div class="col-1">
              <button class="btn btn-link py-1 text-danger bg-transparent" (click)="this.removeSurcharge(surcharge, index)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
             </div>
          </div>


          <div *ngIf="canAddEmptySurcharge()" class="row form-group">
            <!-- Extra empty surcharge field -->
             <div class="col-11">
              <ejs-combobox
                #newSurcharge
                [dataSource]="surchargesList()"
                [fields]="{ text: 'name' }"
                (select)="addEmptySurcharge($event)">
              </ejs-combobox>
             </div>

             <div class="col-1">&nbsp;</div>
          </div>
        </div>
      </div>




      <!-- Price -->
      <!-- <div class="form-group">
        <label for="productType">Godstype pris</label>
        @if (this.productData && this.productData.price) {
          <input type="text" class="form-control">
        }
        @else {
          <input type="text" class="form-control" [value]="'Ingen priser'" [disabled]="true">
        }
      </div> -->
    </form>
  </div>
</app-modal>
