import { CreateZone, Zone, UpdateZone, ZoneMeta, CreateZoneMeta, UpdateZoneMeta } from './zone.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryDto } from 'src/app/organisations/organisation.models';
import { environment } from "src/environments/environment";

const url = environment.baseUrl + '/V1/Zones';
const idpUrl = environment.idp.authority;

@Injectable({
  providedIn: 'root'
})

export class ZoneService {
  constructor(private http: HttpClient) { }

  getAllCountries(): Observable<CountryDto[]> {
    return this.http.get<CountryDto[]>(`${idpUrl}/organizations/countries`);
  }

  getCountryByISO(ISO: string): Observable<CountryDto> {
    return this.http.get<CountryDto>(`${idpUrl}/organizations/countries/${ISO}`);
  }

  getAllZones(): Observable<Zone[]> {
    return this.http.get<Zone[]>(url);
  }

  getZoneById(id: number): Observable<Zone> {
    return this.http.get<Zone>(`${url}/${id}`);
  }

  getZoneByCountryAndZoneTypeId(country: string, zoneTypeId: number): Observable<Zone> {
    return this.http.get<Zone>(`${url}/${country}/${zoneTypeId}`);
  }

  getZoneByCountryAndZoneTypeIdWithMeta(country: string, zoneTypeId: number, meta: string): Observable<Zone> {
    return this.http.get<Zone>(`${url}/${country}/${zoneTypeId}/${meta}`);
  }

  createZone(model: CreateZone): Observable<Zone> {
    return this.http.put<Zone>(url, model);
  }

  updateZone(model: UpdateZone): Observable<Zone> {
    return this.http.put<Zone>(url, model);
  }

  deleteZone(id: number): Observable<Zone> {
    return this.http.delete<Zone>(`${url}/${id}`);
  }

  getAllZoneMetas(): Observable<ZoneMeta[]> {
    return this.http.get<ZoneMeta[]>(`${url}/Meta`);
  }

  getZoneMetaById(id: number): Observable<ZoneMeta> {
    return this.http.get<ZoneMeta>(`${url}/Meta/${id}`);
  }

  createZoneMeta(model: CreateZoneMeta): Observable<ZoneMeta> {
    return this.http.put<ZoneMeta>(`${url}/Meta`, model);
  }

  updateZoneMeta(model: UpdateZoneMeta): Observable<ZoneMeta> {
    return this.http.put<ZoneMeta>(`${url}/Meta`, model);
  }

  deleteZoneMeta(id: number): Observable<ZoneMeta> {
    return this.http.delete<ZoneMeta>(`${url}/Meta/${id}`);
  }
}
