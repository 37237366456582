import { Injectable } from '@angular/core';
import { Constants } from "../constants";
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { AuthOptions, LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { cwd } from 'process';
import { CompanyModel, GroupModel } from '../users/shared/user.model';
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { SessionState } from 'http2';
import { constants } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class TokenService
{
  constructor(private oidc: OidcSecurityService) { }


  public companyName = new Subject<string>();
  private accessTokenData?: string;
  private idTokenData?: string;


  //public CheckAuth(): Observable<LoginResponse>
  //{
  //  return this.oidc.checkAuth();
  //}

  //passValue(data: string) {
  //  //passing the data as the next observable
  //  this.companyName.next(data);
  //}


  saveToken(token: string): void
  {
    sessionStorage.setItem(Constants.token, token);
  }


  setLanguage(lang: string): void
  {
    localStorage.setItem(Constants.language, lang);
  }


  getToken(): string
  {
    var token = sessionStorage.getItem(Constants.token);
    return token ? token : '';
  }


  //deleteToken(): void {
  //  sessionStorage.removeItem(Constants.token);
  //}

  setCompanyGuid(value: string): void
  {
    sessionStorage.setItem(Constants.companyGuid, value);
  }

  //setCompanyName(value: string): void
  //{
  //  //  sessionStorage.setItem(Constants.companyName,value);
  //}

  getGroups(): GroupModel[]
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let groups = [];
      let token: any = jwtDecode(accessToken); //bør gøres EN gang istedet for hver gang
      if (token.groupcount === "1")
      {
        let group = {
          guid: token.groupguid as string,
          name: token.groupname as string
        } as GroupModel;
        groups.push(group);
      }
      else
      {
        let g = token.groupguid as string[];
        let n = token.groupname as string[];
        for (let i = 0; i < g.length; i++)
        {
          let group = {
            guid: g[i],
            name: n[i]
          } as GroupModel;
          groups.push(group);
        }
      }
      return groups;
    }
    return [];
  }


  getAuthentication()
  {
    const result = this.oidc.isAuthenticated();

    return result
  }


  getCompanyName(): string
  {
    const name = sessionStorage.getItem(Constants.companyName);
    if (name != null) return name;
    return '';
  }


  setCompany(company: GroupModel): void
  {
    this.selectedCompanyGuid = company.guid;
    this.selectedCompanyName = company.name;
    this.companyName.next(company.name);

    sessionStorage.setItem(Constants.companyName, company.name);
    sessionStorage.setItem(Constants.companyGuid, company.guid);
  }

  selectedCompanyName: string = ''
  selectedCompanyGuid?: string;


  getCompanyGuid(): string
  {

    let companyGuidStorage = sessionStorage.getItem(Constants.companyGuid);
    if (companyGuidStorage != null) return companyGuidStorage;

    if (!this.selectedCompanyGuid)
    {
      let accessToken = sessionStorage.getItem(Constants.token);
      if (accessToken)
      {
        let token: any = jwtDecode(accessToken); //bør gøres EN gang istedet for hver gang
        return token.companyguid;
      }
      return '';
    }
    else
    {
      return this.selectedCompanyGuid;
    }
    // const guid = sessionStorage.getItem(Constants.companyGuid);
    // return guid ? guid : '';
  }


  getUserRole(): any
  {


    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken); //bør gøres EN gang istedet for hver gang

      // USED FOR TESTING ROLES
      // token.role = 'Employee'
      // token.role = ['SuperAdmin', 'OrganizationAdmin', 'CompanyAdmin']


      return token.role;
    }
    return '';
    //  var token = this.decodeToken();
    //  return token.role;
  }


  isSuperAdmin(): boolean
  {
    let ur = this.getUserRole();
    let isSuperAdminPresent = false;

    if (Array.isArray(ur))
    {

      isSuperAdminPresent = ur.some(
        (item) =>
          item.toLowerCase() === 'superadmin' || ur.includes('superadmin')
      );


    } else
    {
      if (ur.toLowerCase() == 'superadmin' || ur.includes('superadmin'))
      {
        isSuperAdminPresent = true;
      }
    }

    return isSuperAdminPresent;

  }


  getUserEmail(): any
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken);
      return token.email;
    }
    return '';
  }


  getUserName(): any
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken);
      return token.username;
    }
    return '';
  }

  getUserId(): any
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken);
      return token.id;
    }
    return '';
  }

  getOrganizationId(): number
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken);
      return +token.OrganizationId;
    }
    return -1;
  }

  //getAuthenticatedStatus(): any
  //{
  //    return this.oidc.isAuthenticated();
  //}


  // getAccessToken(): string
  // {
  //   return this.oidc.getAccessToken();
  // }


  // getIdToken(): string
  // {
  //   return this.oidc.getIdToken();
  // }


  // getAuthenticatedStatus(): boolean
  // {
  //   return this.oidc.isAuthenticated();
  // }


  getUserLanguage(): any
  {
    const lang = localStorage.getItem(Constants.language);
    return lang ? lang : 'da-DK';
    //return 'da-DK';
  }

  getUserCountryId(): any
  {
    let accessToken = sessionStorage.getItem(Constants.token);
    if (accessToken)
    {
      let token: any = jwtDecode(accessToken);
      return token.countryid;
    }
    return '65';
  }


  //SignoutAndRevokeTokens(): Observable<any>
  //{
  //  return this.oidc.logoffAndRevokeTokens();
  //}


  //Signout(): any
  //{
  //  return this.oidc.logoff();
  //}


  //private decodeToken(): any {
  //  try {
  //    return jwt_decode(this.getToken());
  //  } catch (e){
  //    this.router.navigate(['error'], {queryParams: {error: 'No valid token provided'}});
  //  }
  //}
}
