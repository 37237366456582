
<div class="create-message-component">
  <button *ngIf="this.standalone" class="btn btn-secondary" (click)="messageCreateModal.open();">Opret bedsked | translate</button>


  <div class="d-flex row mb-3">
    <div class="d-flex col-12 justify-content-evenly">
      <label>{{ 'tour.user' | translate }}</label>
      <app-switch
        [isChecked]="this.showUser"
        [disabled]="this.disableSwitch"
        [fullColor]="true"
        (switchEvent)="this.switchMessageMultiselect($event)"
      ></app-switch>
      <label>{{ 'tour.frontVehicle' | translate }}</label>
    </div>
  </div>


  <span *ngIf="formGroup.get('userOrFrontVehicleRequired')?.hasError" class="required-text">
    {{'message.required' | translate}}
  </span>
  <div class="d-flex row mb-3" [ngClass]="{ 'd-none': !this.showUser}">
    <div class="col-12">
      <ng-multiselect-dropdown
        class="w-100 mt-4"
        [(ngModel)]="this.messageForm.selectedFrontVehicles"
        [placeholder]="'messaging.create.frontVehicleSelectPlaceholder' | translate"
        [data]="this.frontVehiclesList"
        [settings]="dropdownSettings"
        (onSelect)="this.onMessageFrontVehicleMultiSelect($event)"
        (onDeSelect)="onMessageFrontVehicleMultiDeselect($event)"
      ></ng-multiselect-dropdown>
    </div>
  </div>

  <div class="d-flex row mb-3" [ngClass]="{ 'd-none': this.showUser}">
    <div class="col-12">
      <ng-multiselect-dropdown
      class="w-100 mt-4"
      [(ngModel)]="this.messageForm.selectedUsers"
      [placeholder]="'messaging.create.userSelectPlaceholder' | translate"
      [data]="this.usersList"
      [settings]="dropdownSettings"
      (onSelect)="this.onMessageUserMultiSelect($event)"
      (onDeSelect)="onMessageUserMultiDeselect($event)"
      ></ng-multiselect-dropdown>
    </div>
  </div>


  <form [formGroup]="this.formGroup">
    <input hidden type="number" formControlName="frontVehicleId" [(ngModel)]="this.messageForm.frontVehicleId">
    <input hidden type="number" formControlName="userId" [(ngModel)]="this.messageForm.userId">
 
    <div class="d-flex row mb-3">
      <div class="col-12">
        <span *ngIf="formGroup.controls['message'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
        <span *ngIf="formGroup.controls['message'].hasError('maxlength')" class="required-text">
          {{'message.maxLength' | translate: {number: 255} }}
        </span>
        <textarea
          class="form-control"
          name="message"
          formControlName="message"
          rows="7"
          [placeholder]="'messaging.create.messagePlaceholder' | translate"
          [(ngModel)]="this.messageForm.message"
        ></textarea>
      </div>
    </div>

    <div class="d-flex row mb-3">
      <div class="col-12">
        <select class="form-select" name="type" formControlName="type" [(ngModel)]="this.messageForm.type">
          @for(type of this.messageTypes; track $index) {
            <option value="{{ type.value }}">{{ type.label | translate }}</option>
          }
        </select>
      </div>
    </div>
  </form>
</div>
