<div class="page-content mt-2 pb-2 ps-1">
  <ejs-sidebar #sidebar>
    <ejs-treeview #tree [fields]="treeFields" (nodeSelected)="loadRoutingContent($event)" />
  </ejs-sidebar>

  <div class="main-content ps-2 me-1">
    <ejs-toolbar class="mb-2" (clicked)="toolbarClicked()">
      <e-items>
        <e-item prefixIcon="e-tbar-menu-icon" tooltipText="{{'menu' | translate}}" />
        <e-item text="{{'surcharge' | translate}}" />
      </e-items>
    </ejs-toolbar>

    <ejs-grid
      (toolbarClick)="toolbarClick($event)"
      (actionComplete)="onActionCompleteSurcharge($event)"
      [dataSource]="surcharges"
      [allowPaging]="true"
      [allowSorting]="true"
      [filterSettings]="filterSettings"
      [allowGrouping]="true"
      [editSettings]="editSettings"
      [groupSettings]="groupOptions"
      [toolbar]="toolbar"
      [pageSettings]="pageSettings"
    >
      <e-columns>
        <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
        <e-column field="surchargeType.name" headerText="{{'address.type' | translate}}" />
        <e-column field="name" headerText="{{'common.name' | translate}}" />
        <e-column field="chargePerKm" headerText="{{'chargePerKm' | translate}}" />
        <e-column field="staticCharge" headerText="{{'staticCharge' | translate}}" />
      </e-columns>
    </ejs-grid>

    <ng-template #createSurchargeTypeModal>
      <app-base-modal
        (accept)="createSurchargeType()"
        (cancel)="closeModal()"
        [titleTranslationKey]="'freightTypes.create'"
        [closeTranslationKey]="'freightTypes.closeModal'"
        [acceptTranslationKey]="'freightTypes.create'"
        [formGroup]="createSurchargeTypeFormGroup"
      >
        <label for="surchargeTypeName">
          {{'surchargeType' | translate}}
          <span *ngIf="createSurchargeTypeFormGroup.controls['surchargeTypeName'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input formControlName="surchargeTypeName" class="form-control form-control-sm mb-2" type="text" id="surchargeTypeName" name="surchargeTypeName" />
      </app-base-modal>
    </ng-template>

    <ng-template #createSurchargeModal>
      <app-base-modal
        (accept)="createSurcharge()"
        (cancel)="closeModal()"
        [titleTranslationKey]="'freightTypes.create'"
        [closeTranslationKey]="'freightTypes.closeModal'"
        [acceptTranslationKey]="'freightTypes.create'"
        [formGroup]="createSurchargeFormGroup"
      >
        <label for="surchargeTypeId">{{'surchargeType' | translate}}
          <span *ngIf="createSurchargeFormGroup.controls['surchargeTypeId'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <ejs-dropdownlist
          (change)="onSurchargeTypeUpdate($event)"
          id="surchargeTypes"
          [dataSource]="surchargeTypes"
          [value]="surchargeTypeId"
          [fields]="checkFields"
          [placeholder]="checkWaterMark"
          [popupHeight]="popHeight"
          [noRecordsTemplate]="'company.basicInformation.noData' | translate"
        />

        <label for="surchargeName">{{'surcharge' | translate}}
          <span *ngIf="createSurchargeFormGroup.controls['surchargeName'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input formControlName="surchargeName" class="form-control form-control-sm mb-2" type="text" id="surchargeName" name="surchargeName" />

        <label for="chargePerKm">{{'chargePerKm' | translate}}
          <span *ngIf="createSurchargeFormGroup.controls['chargePerKm'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input formControlName="chargePerKm" class="form-control form-control-sm mb-2" type="number" id="chargePerKm" name="chargePerKm" />

        <label for="staticCharge">{{'staticCharge' | translate}}
          <span *ngIf="createSurchargeFormGroup.controls['staticCharge'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input formControlName="staticCharge" class="form-control form-control-sm mb-2" type="number" id="staticCharge" name="staticCharge" />
      </app-base-modal>
    </ng-template>
  </div>
