<div class="simple-address__component">
  @if (this.addresses.length == 0) {
    <p>{{ 'customer.noAddressesToShow' | translate }}</p>
  }


  <ng-container *ngFor="let addressForm of this.addresses.controls; let i = index">
    <form [formGroup]="addressForm" class="position-relative p-0">
      <div class="d-flex flex-wrap col-12 align-items-end" [ngClass]="{ 'col-md-6 col-xl-9': (!addressForm.value.latitude && !addressForm.value.longitude) }">
        <div *ngIf="!this.readOnly" class="col address-lookup--component position-relative">
          <label class="col-12" for="search">{{'address.searchAddress' | translate}}</label>
          <input
            #search
            type="text"
            name="search"
            class="form-control col"
            [(ngModel)]="addressSearch[i]"
            (ngModelChange)="this.addressSearchUpdate.next($event)"
            [ngModelOptions]="{standalone: true}"
            placeholder="{{'address.searchAddressDesc' | translate}}"
            autocomplete="off"
            [readonly]="readOnly"
          >


          <app-tomtom-map-picker (LatLng)="this.updateCoordinates($event, i)" />

          <ul *ngIf="addressSearch[i]" class="resultList" class="list-group position-absolute mt-1">
            <li role="button" *ngFor="let result of searchResults" (click)="fillAddressForm(result, addresses, i)" class="list-group-item cursor">
              {{generateAdressTitle(result)}}
            </li>
          </ul>
        </div>

        <div *ngIf="!this.readOnly && (addressForm.value.latitude && addressForm.value.longitude)" class="col-3">
          <button
            #t="ngbTooltip"
            ngbTooltip="Streetview"
            placement="top"
            class="btn btn-outline-secondary ms-2"
            style="width: 130px; max-height: fit-content;"
            [disabled]="!addressForm.get('street')?.value"
            (click)="emitStreetview(i)"
          >
            <fa-icon [icon]="this.faStreetView"></fa-icon>
            &nbsp; Streetview
          </button>
        </div>
      </div>


      <div class="form-group row p-0 m-0">
        <div class="col-md-6 p-0">
          <label for="street">
            {{'address.street' | translate}}
            <span *ngIf="addressForm.get('street')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
            <span *ngIf="addressForm.get('street')?.hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <input id="street" type="text" formControlName="street" class="form-control" [readonly]="readOnly">
        </div>

        <div class="col-md-3 pe-0">
          <label for="city">
            {{'address.city' | translate}}
            <span *ngIf="addressForm.get('city')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
            <span *ngIf="addressForm.get('city')?.hasError('required') && !readOnly" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <input id="city" type="text" formControlName="city" class="form-control" [readonly]="readOnly">
        </div>

        <div class="col-md-3 pe-0">
          <label for="zipcode">
            {{'address.zipcode' | translate}}
            <span *ngIf="addressForm.get('zipcode')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 10} }}
            </span>
            <span *ngIf="addressForm.get('zipcode')?.hasError('required') && !readOnly" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <input id="zipcode" type="text" formControlName="zipcode" class="form-control" [readonly]="readOnly">
        </div>
      </div>

      <!--------------------------------------------------->

      <div class="form-group row p-0 m-0">
        <div class="col-md-6 p-0">
          <label for="state">
            {{'address.state' | translate}}
            <span *ngIf="addressForm.get('state')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <input id="state" type="text" formControlName="state" class="form-control" [readonly]="readOnly">
        </div>

        <div class="col-md-6 pe-0">
          <label for="country">
            {{'address.country' | translate}}
            <span *ngIf="addressForm.get('country')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
            <span *ngIf="addressForm.get('country')?.hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <input id="country" type="text" formControlName="country" class="form-control" [readonly]="readOnly">
        </div>
      </div>

      <div *ngIf="showDescription" class="row">
        <div class="col-12">
          <label for="description">
            {{'address.description' | translate}}
            <span
              *ngIf="addressForm.get('description')?.hasError('maxlength')"
              class="required-text"
            >
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <textarea
            autosize
            id="description"
            type="text"
            formControlName="description"
            class="form-control"
            [readonly]="readOnly"
          ></textarea>
        </div>

        <div *ngIf="this.readOnly && (addressForm.value.latitude && addressForm.value.longitude)" class="col-12">
          <button
            #t="ngbTooltip"
            ngbTooltip="Streetview"
            placement="top"
            class="btn btn-outline-secondary ms-2"
            style="width: 130px; max-height: fit-content;"
            [disabled]="!addressForm.get('street')?.value"
            (click)="emitStreetview(i)"
          >
            <fa-icon [icon]="this.faStreetView"></fa-icon>
            &nbsp; Streetview
          </button>
        </div>
      </div>

      <!-- <div class="row">
        <app-tomtom-map-picker (LatLng)="this.updateCoordinates($event, i)" />
      </div> -->



      <!--------------------------------------------------->



      <div *ngIf="!this.singleAddress" class="form-group row">
        <div class="col-md-12">
          <button
            *ngIf="!readOnly"
            type="button"
            class="btn btn-danger position-absolute top-0 end-0"
            (click)="removeAddressAtIndex(addressForm)"
          >
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </div>
    </form>

    <hr>

  </ng-container>

  <div *ngIf="!this.singleAddress" class="row">
    <div class="col-md-12 text-center">
      <button
        *ngIf="!readOnly"
        type="button"
        class="btn btn-primary"
        (click)="addAddress()"
      >
        {{'address.addAddress' | translate}}
      </button>
    </div>
  </div>
</div>
