import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { CreateSurcharge, CreateSurchargeType, Surcharge, SurchargeType, UpdateSurcharge, UpdateSurchargeType } from "./shared/surcharge.model";
import { SurchargeService } from "./shared/surcharge.service";
import { DropDownListModule, MultiSelectChangeEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { EditService, EditSettingsModel, FilterService, GridModule, GroupService, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from "@syncfusion/ej2-angular-grids";
import { firstValueFrom } from 'rxjs';
import { NodeSelectEventArgs, SidebarComponent, SidebarModule, ToolbarModule, TreeViewComponent, TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { BaseModal } from "src/app/freight-types/components/base-modal/base-modal.component";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
  selector: 'app-surcharge',
  templateUrl: './surcharge.component.html',
  styleUrl: './surcharge.component.scss',
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BaseModal,
    DropDownListModule,
    GridModule,
    DialogModule,
    ToolbarModule,
    SidebarModule,
    TreeViewModule
  ],
  providers: [
    GroupService,
    ToolbarService,
    SortService,
    EditService,
    PageService,
    FilterService
  ]
})

export class SurchargeComponent implements OnInit {
  modal?: NgbActiveModal;
  createSurchargeTypeFormGroup: UntypedFormGroup;
  createSurchargeFormGroup: UntypedFormGroup;

  surchargeTypes: SurchargeType[] = [];
  surchargeType: SurchargeType = {} as SurchargeType;
  surcharges: Surcharge[] = [];
  surcharge: Surcharge = {} as Surcharge;

  surchargeTypeId: number = 0;

  treeMenuItems: Object[] = [];
  treeFields: Object = {};

  translations: any = {
    prices: '',
    pricing: '',
    indexAdjustment: '',
    surcharges: '',
    columnGrouping: '',
    gridEdit: '',
    gridDelete: '',
    gridUpdate: '',
    gridCancel: '',
    gridSearch: '',
    noData: ''
  }

  checkFields: Object = { text: 'name', value: 'id' };
  checkWaterMark: string = '';
  popHeight: string = '350px';

  groupOptions: Object = { showGroupedColumn: false, columns: ['surchargeType.name'] };
  pageSettings: PageSettingsModel = { pageCount: 5 };
  filterSettings: Object = { type: 'Excel' };
  editSettings: EditSettingsModel = { allowEditing: true, allowDeleting: true };
  toolbar!: (ToolbarItems | { text: string; id: string; prefixIcon: string })[];

  @ViewChild('createSurchargeModal') dialogSurcharge!: ElementRef;
  @ViewChild('sidebar') sidebar!: SidebarComponent;
  @ViewChild('tree') treeview!: TreeViewComponent;
  @ViewChild('createSurchargeTypeModal') dialogType!: ElementRef;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private modalService: NgbModal, private surchargeService: SurchargeService, private toaster: ToastrService, private translate: TranslateService) {
    this.createSurchargeTypeFormGroup = this.formBuilder.group({
      surchargeTypeName: ['', Validators.required]
    });

    this.createSurchargeFormGroup = this.formBuilder.group({
      surchargeName: ['', Validators.required],
      chargePerKm: null,
      staticCharge: null,
      surchargeTypeId: ['', Validators.required],
    });
  }

  async ngOnInit() {
    await this.getTranslations();
    this.getAllSurchargeTypes();
    this.getAllSurcharges();

    this.toolbar = [
      {
        text: this.translate.instant('addSurchargeType'),
        id: 'addType',
        prefixIcon: 'e-add'
      },
      {
        text: this.translate.instant('addSurcharge'),
        id: 'addSurcharge',
        prefixIcon: 'e-add'
      },
      'Edit',
      'Delete',
      'Update',
      'Cancel',
      'Search'
    ];

    this.treeMenuItems = [
      {
        id: 1,
        name: this.translations.prices,
        url: 'pricing',
        expanded: true,
        childNodes: [
          {
            id: 1.1,
            name: this.translations.pricing,
            url: 'pricing'
          },
          {
            id: 1.2,
            name: this.translations.indexAdjustment,
            url: 'pricing/index-adjustment'
          },
          {
            id: 1.3,
            name: this.translations.surcharges,
            url: 'pricing/surcharge',
            selected: true
          }
        ]
      }
    ];

    this.treeFields = {
      dataSource: this.treeMenuItems,
      id: 'id',
      text: 'name',
      selected: 'selected',
      expanded: 'expanded',
      child: 'childNodes'
    };

    this.checkWaterMark = this.translate.instant('selectSurchargeType');

    setTimeout(() => {
      if((document.querySelector('.e-groupdroparea') as HTMLElement)) {
        (document.querySelector('.e-groupdroparea') as HTMLElement).textContent = this.translations.columnGrouping;
      }

      if((document.querySelector('.e-toolbar .e-input-group > input') as HTMLInputElement)) {
        (document.querySelector('.e-toolbar .e-input-group > input') as HTMLInputElement).placeholder = this.translations.gridSearch;
      }

      if(document.querySelector('.e-emptyrow > td')) {
        (document.querySelector('.e-emptyrow > td') as HTMLElement).textContent = this.translations.noData;
      }

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-item').forEach((toolbarItem) => {
        (toolbarItem as HTMLElement).title = '';
      });

      if(document.querySelector('.e-toolbar-left .e-toolbar-item .e-edit')?.nextSibling) {
        document.querySelector('.e-toolbar-left .e-toolbar-item .e-edit')!.nextSibling!.textContent = this.translations.gridEdit;
      }

      if(document.querySelector('.e-toolbar-left .e-toolbar-item .e-delete')?.nextSibling) {
        document.querySelector('.e-toolbar-left .e-toolbar-item .e-delete')!.nextSibling!.textContent = this.translations.gridDelete;
      }

      if(document.querySelector('.e-toolbar-left .e-toolbar-item .e-update')?.nextSibling) {
        document.querySelector('.e-toolbar-left .e-toolbar-item .e-update')!.nextSibling!.textContent = this.translations.gridUpdate;
      }

      if(document.querySelector('.e-toolbar-left .e-toolbar-item .e-cancel')?.nextSibling) {
        document.querySelector('.e-toolbar-left .e-toolbar-item .e-cancel')!.nextSibling!.textContent = this.translations.gridCancel;
      }

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-right .e-toolbar-item .e-input-group-icon').forEach((searchIcon) => {
        (searchIcon as HTMLElement).title = '';
      });

      document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
        (pagingButton as HTMLElement).title = '';
      });
    }, 1000);
  }

  async getTranslations() {
    try {
      this.translations.prices = await firstValueFrom(this.translate.get('prices'));
      this.translations.pricing = await firstValueFrom(this.translate.get('pricing'));
      this.translations.indexAdjustment = await firstValueFrom(this.translate.get('indexAdjustment'));
      this.translations.columnGrouping = await firstValueFrom(this.translate.get('columnGrouping'));
      this.translations.gridEdit = await firstValueFrom(this.translate.get('btn.edit'));
      this.translations.gridDelete = await firstValueFrom(this.translate.get('freightTypes.delete'));
      this.translations.gridUpdate = await firstValueFrom(this.translate.get('companyUsers.companyUsersRolesSaveBtn'));
      this.translations.gridCancel = await firstValueFrom(this.translate.get('organization.cancel'));
      this.translations.gridSearch = await firstValueFrom(this.translate.get('company.search'));
      this.translations.noData = await firstValueFrom(this.translate.get('company.basicInformation.noData'));
      this.translations.surcharges = await firstValueFrom(this.translate.get('surcharge'));
    }

    catch(error) {
      console.error('Fejl: ', error);
    }
  }

  loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.treeview.getTreeData(args.node);
    this.router.navigate([data[0].url]);
  }

  toolbarClicked(): void {
    this.sidebar.toggle();
  }

  toolbarClick(args: any): void {
    if (args.item.properties.id === 'addType') {
      this.openCreateSurchargeTypeModal(this.dialogType);
    }

    if (args.item.properties.id === 'addSurcharge') {
      this.openCreateSurchargeModal(this.dialogSurcharge);
    }
  }

  onActionCompleteSurcharge(args: any): void {
    if(args.requestType === 'save') {
      const model: UpdateSurcharge = {
        id: args.data.id,
        name: args.data.name,
        surchargeTypeId: args.data.surchargeType.id,
        chargePerKm: args.data.chargePerKm,
        staticCharge: args.data.staticCharge,
        currencyId: 0,
        adjustmentIndexId: null,
        customerId: undefined
      }

      this.updateSurcharge(model);
    }

    if(args.requestType === 'delete') {
      this.deleteSurcharge(args.data[0].id);
    }
  }

  onSurchargeTypeUpdate(event: MultiSelectChangeEventArgs): void {
    if (event.value == undefined) return;
    this.surchargeTypeId = Number(event.value);
    this.createSurchargeFormGroup.patchValue({ surchargeTypeId: Number(event.value) })
  }

  openCreateSurchargeTypeModal(modal: ElementRef<any>): void {
    this.modal = this.modalService.open(modal, { backdrop: 'static', keyboard: false });
    document.getElementById('surchargeTypeName')?.focus();
  }

  openCreateSurchargeModal(modal: ElementRef<any>): void {
    this.modal = this.modalService.open(modal, { backdrop: 'static', keyboard: false });
    document.getElementById('surchargeName')?.focus();
  }

  closeModal(): void {
    this.modal?.dismiss();
    this.createSurchargeTypeFormGroup.reset();
    this.createSurchargeFormGroup.reset();
    this.surchargeTypeId = 0;
  }

  getAllSurchargeTypes(): void {
    this.surchargeService.getAllSurchargeTypes().subscribe({
      next: (response) => this.surchargeTypes = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  getSurchargeTypeById(id: number): void {
    this.surchargeService.getSurchargeTypeById(id).subscribe({
      next: (response) => this.surchargeType = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  createSurchargeType(): void {
    const model: CreateSurchargeType = {
      name: this.createSurchargeTypeFormGroup.get('surchargeTypeName')!.value
    }

    this.surchargeService.createSurchargeType(model.name).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('surchargeTypeCreated'));
        this.getAllSurchargeTypes();
        this.closeModal();
      }
    });
  }

  updateSurchargeType(): void {
    const model: UpdateSurchargeType = {
      id: 0,
      name: ''
    }

    this.surchargeService.updateSurchargeType(model.id, model.name).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('surchargeTypeUpdated'));
        this.getAllSurchargeTypes();
      }
    });
  }

  deleteSurchargeType(id: number): void {
    this.surchargeService.deleteSurchargeType(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('surchargeTypeDeleted'));
        this.getAllSurchargeTypes();
      }
    });
  }

  getAllSurcharges(): void {
    this.surchargeService.getAllSurcharges().subscribe({
      next: (response) => this.surcharges = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  getSurchargeById(id: number): void {
    this.surchargeService.getSurchargeById(id).subscribe({
      next: (response) => this.surcharge = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  createSurcharge(): void {
    const model: CreateSurcharge = {
      name: this.createSurchargeFormGroup.get('surchargeName')!.value,
      surchargeTypeId: this.surchargeTypeId,
      chargePerKm: this.createSurchargeFormGroup.get('chargePerKm')?.value,
      staticCharge: this.createSurchargeFormGroup.get('staticCharge')?.value,
      currencyId: 0, //DK ! TEMP!!! /TODO!
      adjustmentIndexId: null,
      customerId: undefined
    }

    this.surchargeService.createSurcharge(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('surchargeCreated'));
        this.getAllSurcharges();
        this.closeModal();
      }
    });
  }

  updateSurcharge(model: UpdateSurcharge): void {
    this.surchargeService.updateSurcharge(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('surchargeUpdated'));
        this.getAllSurcharges();
      }
    });
  }

  deleteSurcharge(id: number): void {
    this.surchargeService.deleteSurcharge(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('surchargeDeleted'));
        this.getAllSurcharges();
      }
    });
  }
}
