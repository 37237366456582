import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  CopyTourModel,
  CreateRangeTourModel,
  CreateTourModel,
  StartTourModel,
  TourModel,
  UpdateTourModel
} from "./tour.model";
import {NoteModel} from "../../shared/note-widget/shared/note.model";
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { DataExchangeModel } from '../../shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/Tours'

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<TourModel[]> {
    return this.http.get<TourModel[]>(`${url}/Active`);
  }

  getSingle(id: number): Observable<TourModel>{
    return this.http.get<TourModel>(`${url}/${id}`);
  }
  
  getSingleSimple(id: number): Observable<TourModel>{
    return this.http.get<TourModel>(`${url}/${id}/simple`);
  }

  create(model: CreateTourModel, file: File, showinapp: any): Observable<TourModel>{
    let formData: FormData = new FormData();
    formData.append('json', JSON.stringify(model))
    formData.append('file', file);
    formData.append('showinapp', showinapp);
    return this.http.post<TourModel>(url, formData);
  }

  createRange(model: CreateRangeTourModel, file: File, showinapp: any): Observable<TourModel>{
    let formData: FormData = new FormData();
    formData.append('jsonmodel', JSON.stringify(model))
    formData.append('file', file);
    formData.append('showinapp', showinapp);
    return this.http.post<TourModel>(`${url}/Range`, formData);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${url}/${id}`);
  }

  update(id: number, model: UpdateTourModel): Observable<TourModel>{
    return this.http.put<TourModel>(`${url}/${id}`, model);
  }

  startTour(id: number, model: StartTourModel): Observable<TourModel>{
    return this.http.patch<TourModel>(`${url}/${id}/Start`, model);
  }

  restartTour(id: number, model: StartTourModel): Observable<TourModel>{
    return this.http.patch<TourModel>(`${url}/${id}/Restart`, model);
  }

  getAttachments(tourId: number): Observable<TourModel>{
    return this.http.get<TourModel>(`${url}/${tourId}/Attachments`)
  }

  createAttachment(tourId: number, attachment: AttachmentCreateModel):Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('showInApp', attachment.showInApp.toString());
    return this.http.post(`${url}/${tourId}/Attachment`, formData);
  }

  deleteAttachment(tourId: number, attachmentId: number): Observable<TourModel>{
    return this.http.delete<TourModel>(`${url}/${tourId}/Attachment/${attachmentId}`);
  }

  getNotes(tourId: number): Observable<TourModel>{
    return this.http.get<TourModel>(`${url}/${tourId}/Notes`);
  }

  deleteNote(tourId: number, noteId: number): Observable<TourModel>{
    return this.http.delete<TourModel>(`${url}/${tourId}/Note/${noteId}`);
  }

  createNote(tourId: number, model: NoteModel): Observable<TourModel> {
    return this.http.post<TourModel>(`${url}/${tourId}/Note`, model);
  }

  getEndedTours(date: any): Observable<TourModel[]>{
    return this.http.get<TourModel[]>(`${url}/Ended/${date}`);
  }

  getLastTourByVehicleWithPosition(vehicleId: number): Observable<TourModel[]>{
    return this.http.get<TourModel[]>(`${url}/LastByVehicleWithPosition/${vehicleId}`);
  }

  getBetween(start: any, end: any, resourceType: string, resourceId: number): Observable<TourModel[]> {
    let parameters = {params: new HttpParams().set('resourceType', resourceType).set('resourceId', resourceId)};
    return this.http.get<TourModel[]>(`${url}/${start}/${end}`, parameters);
  }

  getBetweenEnded(start: any, end: any): Observable<TourModel[]> {
    return this.http.get<TourModel[]>(`${url}/BetweenEnded/${start}/${end}`);
  }

  release(ids: number[]): Observable<TourModel[]>{
    const model = {ids: ids}
    return this.http.patch<TourModel[]>(`${url}/Release`, {ids: ids});
  }

  pullBack(ids: number[]): Observable<TourModel[]>{
    return this.http.patch<TourModel[]>(`${url}/PullBack`, {ids: ids});
  }

  copyRange(model: CopyTourModel): Observable<TourModel[]> {
    return this.http.post<TourModel[]>(`${url}/Copy`, model);
  }

  cancelTour(id: number, model: any): Observable<TourModel>
  {
    return this.http.patch<TourModel>(`${url}/${id}/Cancel`, model);
  }

  cancelTourRegistration(id: number, model: any): Observable<TourModel>
  {
    return this.http.patch<TourModel>(`${url}/${id}/CancelRegistration`, model);
  }

  deleteAddress(tourId: number,addressId:number){
    return this.http.delete<TourModel>(`${url}/${tourId}/Address/${addressId}`);
  }

  getImportSchemas(){
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  processImportFile(file: File, schemaid: number, customerid: number) :Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataExchangeSchemaId', schemaid.toString());
    formData.append('customerId', customerid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }
}
